import { useRef } from 'react';
import { CheckCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import NoteToClient from './NoteToClient';
import PriceInformationForService from './PriceInformationForService';
import ServiceEntryModal from './ServiceEntryModal';

import classes from './ServiceEntry.module.less';

/**
 * ServiceEntry component, which shows informations about a selected service in the service overview step (Requires signing process shopping cart context)
 * @param {Object} inputParameters - Input parameters of component
 * @param {Object} inputParameters.item - Selected service
 * @param {Object} inputParameters.activeDiscount - Optional active discount for the service
 * @returns {JSX.Element} shows informations about a selected service
 * @component
 */
const ServiceEntry = ({ item, activeDiscount }) => {
  const modalRef = useRef();
  const { texts, officialReasonText } = item;
  const { name } = texts;

  const showModal = () => {
    if (modalRef?.current?.openModal) modalRef.current.openModal();
  };

  return (
    <div className={classes.serviceEntry}>
      <CheckCircleFilled className={classes.icon} />
      <div className={classes.infos}>
        <div className={classes.titles}>
          <span className={classes.title}>
            <h3>{name}</h3>
            <DisplayModalButton benefits={texts.benefits} showModal={showModal} />
            <ServiceEntryModal item={item} modalRef={modalRef} />
          </span>
          <NoteToClient noteToClient={officialReasonText} />
        </div>
        <PriceInformationForService item={item} activeDiscount={activeDiscount} />
      </div>
    </div>
  );
};

/**
 * Displays the open modal button if the benefits text is not empty
 * @param {Object} inputParameters - Input parameters of component
 * @param {String} inputParameters.benefits the benefits text of the service
 * @param {Function} inputParameters.showModal the function to open the modal
 * @returns {JSX.Element} the open modal button or undefined
 */
const DisplayModalButton = ({ benefits, showModal }) => {
  if (RichText.isRichTextEmpty(benefits)) {
    return null;
  }
  return (
    <button className={classes.modalButton} onClick={showModal} type="button">
      <InfoCircleFilled className={classes.modalButtonIcon} />
    </button>
  );
};

export default ServiceEntry;
