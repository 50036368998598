import { Select } from 'formik-antd';
import FormItem from 'components/common/FormItem';
import { ErrorMessage } from 'formik';
import classes from './SelectInputField.module.less';

const { Option } = Select;

const SelectInputField = ({ name, placeholder, options, label, className, enableErrorHandling, errorComponent }) => {
  const optionsComponents = options?.map((option) => {
    return (
      <Option label={option.label} value={option.value} key={option.value}>
        <div className="demo-option-label-item">{option.label}</div>
      </Option>
    );
  });
  return (
    <div className={className}>
      <FormItem className="hide-form-item-colon form-item-no-margin" name={name} label={label}>
        <div className={classes.selectInputField}>
          <Select
            name={name}
            mode="multiple"
            allowClear
            placeholder={placeholder}
            optionLabelProp="label"
            disabled={!options}
          >
            {optionsComponents}
          </Select>
        </div>
        {enableErrorHandling && (
          <div className={classes.errorAlert}>
            <ErrorMessage component={errorComponent} name={name} />
          </div>
        )}
      </FormItem>
    </div>
  );
};

export default SelectInputField;
