import { gql } from '@apollo/client';
import adminEmailPreferencesFragment from './adminEmailPreferencesFragment';
import adminShoppingCartPreferencesFragment from './adminShoppingCartPreferencesFragment';
import userMonthlyPaymentPreferencesFragment from '../user/userMonthlyPaymentPreferencesFragment';
import userDigitalSignaturePreferencesFragment from '../user/userDigitalSignaturePreferencesFragment';

const adminTenantSettingsFragment = gql`
  fragment adminTenantSettingsFragment on Settings {
    _id
    hideCountryInAddresses
    shoppingCartPreferences {
      ...adminShoppingCartPreferencesFragment
    }
    digitalSignaturePreferences {
      ...userDigitalSignaturePreferencesFragment
    }
    emailPreferences {
      ...adminEmailPreferencesFragment
    }
    monthlyPaymentPreferences {
      ...userMonthlyPaymentPreferencesFragment
    }
  }
  ${adminShoppingCartPreferencesFragment}
  ${userDigitalSignaturePreferencesFragment}
  ${adminEmailPreferencesFragment}
  ${userMonthlyPaymentPreferencesFragment}
`;

export default adminTenantSettingsFragment;
