/* eslint-disable no-param-reassign */
import { useCachedQuery } from 'graphql/utils';
import { useLibraryContext } from 'contexts/LibraryContext';
import { richEditorSchemaQuery } from 'graphql/queries';
import i18n from 'i18n';
import { find, intersection } from 'lodash';
import { forwardRef } from 'react';
import { MentionContextProvider } from './MentionContext';
import { BaseRichEditorField } from './RichEditor';
import { RichEditorPlaceholders } from './RichEditorPlaceholders';

function schemaForEach(schema, fn) {
  return schema.forEach((element) => {
    fn(element);
    element?.fields?.length && schemaForEach(element.fields, fn);
  });
}
function schemaMap(schema, fn) {
  return schema.map((element) => ({
    ...fn(element),
    ...(element?.fields?.length && { fields: schemaMap(element.fields, fn) }),
  }));
}
const makeRichEditorPlaceholdersSchema = (schema, { i18nKey, mentionType, forcedKeys, allowedUses }) => {
  const newSchema = schemaMap(schema, (e) => e); // fast cloneDeep

  schemaForEach(newSchema, (row) => {
    if (row.use && intersection(row.use, allowedUses).length === 0) row.fields = [];
    if (row.fields)
      row.fields = row.fields
        .filter((f) => (f.hiddenByDefault ? forcedKeys?.includes?.(f.name) : true))
        .filter((field) => !field.use || intersection(field.use, allowedUses).length !== 0);
  });
  schemaForEach(newSchema, (row) => {
    const rowName = row.name;
    const rowI18nPath = `${i18nKey}.${rowName}`;
    row.title = row.label || i18n.t(`${rowI18nPath}.label`);
    if (row.fields) {
      const isCategoryTotals = rowName === 'categoryTotals';
      row.fields.forEach((field) => {
        field.parentName = rowName;
        field.onClick = ({ insertMention }) => {
          insertMention({ name: field.name, mentionType: mentionType(field) });
        };
        if (isCategoryTotals) field.mentionName = i18n.t(`${rowI18nPath}.mention`, { name: field.label });
      });
    }
  });
  return newSchema;
};

const defaultMentionContextValue = ({ placeholdersSchema }) => {
  const renderMention = ({ element }) => {
    const { mentionType, oldMentionType, name } = element;
    if (mentionType === 'common') {
      return i18n.t(`admin.Placeholders.${name}.label`);
    }
    if (mentionType === 'variable') {
      return find(find(placeholdersSchema, { name: 'variables' })?.fields, { name })?.label;
    }
    if (mentionType === 'category') {
      return find(
        find(find(placeholdersSchema, { name: 'shoppingCart' })?.fields, { name: 'categoryTotals' })?.fields,
        {
          name,
        },
      )?.mentionName;
    }
    if (mentionType === 'deleted') {
      if (oldMentionType === 'category') return i18n.t('admin.Placeholders.categoryTotals.deleted');
      if (oldMentionType === 'variable') return i18n.t('admin.Placeholders.variables.deleted');
    }
    return '?';
  };
  return { renderMention };
};

const useRichEditorSchema = ({
  placeholders = [],
  variables = {},
  modifyPlaceholderSchema = (e) => e,
  allowedUses = [],
} = {}) => {
  const { isLibrary } = useLibraryContext();
  const { data } = useCachedQuery(richEditorSchemaQuery, {
    fetchPolicy: 'network-only',
    variables: { isLibrary, ...variables },
  });
  if (!data?.getPlaceholdersSchema) return null;
  return makeRichEditorPlaceholdersSchema(modifyPlaceholderSchema(data.getPlaceholdersSchema), {
    i18nKey: 'admin.Placeholders',
    // eslint-disable-next-line no-nested-ternary
    mentionType: (f) => (f.label ? (f.parentName === 'categoryTotals' ? 'category' : 'variable') : 'common'),
    forcedKeys: placeholders,
    allowedUses,
  });
};
const RichEditorField_ = (props, ref) => {
  const placeholdersSchema = useRichEditorSchema(props);
  const { hidePlaceholdersButton } = props;
  const toolbarChildren = () => {
    if (hidePlaceholdersButton) return null;
    return [<RichEditorPlaceholders schema={placeholdersSchema} />];
  };
  const { rootElement = 'div' } = props;
  if (!placeholdersSchema?.length) return null;
  return (
    <MentionContextProvider
      value={{ ...defaultMentionContextValue({ placeholdersSchema }), schema: placeholdersSchema }}
    >
      <BaseRichEditorField ref={ref} {...props} toolbarChildren={toolbarChildren} rootElement={rootElement} />
    </MentionContextProvider>
  );
};
export const RichEditorField = forwardRef(RichEditorField_);
