import i18n from 'i18n';
import * as Yup from 'yup';

/**
 * The formik validation schema for the KP Email Service Settings
 * @returns Yup validation shape
 */
const KPEmailServiceSettingsSchema = () => ({
  mailServiceProvider: Yup.string()
    .required()
    .nullable()
    .label(i18n.t('admin.Setting.Email.inputs.mailServiceProvider.label')),
  from: Yup.string()
    .nullable()
    .matches(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i, i18n.t('admin.Setting.Email.inputs.from.invalidEmailFormat'))
    .label(i18n.t('admin.Setting.Email.inputs.from.label')),
  domainName: Yup.string()
    .nullable()
    .matches(
      /^(?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+(?:[A-Za-z]{2,})$/,
      i18n.t('admin.Setting.Email.inputs.customDomain.validationError'),
    ),
  useCustomDomain: Yup.boolean().nullable(),
});

export const updateKPEmailSettingSchema = () => Yup.object().shape(KPEmailServiceSettingsSchema());

/**
 * The formik validation schema for the SMTP Email Settings
 * @returns Yup validation shape
 */
const SMTPSettingsSchema = () => ({
  mailServiceProvider: Yup.string()
    .required()
    .nullable()
    .label(i18n.t('admin.Setting.Email.inputs.mailServiceProvider.label')),
  from: Yup.string()
    .nullable()
    .matches(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i, i18n.t('admin.Setting.Email.inputs.from.invalidEmailFormat'))
    .label(i18n.t('admin.Setting.Email.inputs.from.label')),
  smtpUserName: Yup.string().nullable().label(i18n.t('admin.Setting.Email.inputs.smtpUserName')),
  smtpPassword: Yup.string().nullable().label(i18n.t('admin.Setting.Email.inputs.smtpPassword')),
  smtpServer: Yup.string().nullable().label(i18n.t('admin.Setting.Email.inputs.smtpServer')),
  smtpPort: Yup.string().nullable().label(i18n.t('admin.Setting.Email.inputs.smtpPort')),
  useTLS: Yup.boolean().nullable(),
  useSSLv3: Yup.boolean().nullable(),
  requireTLS: Yup.boolean().nullable(),
});

export const updateSMTPEmailSettingSchema = () => Yup.object().shape(SMTPSettingsSchema());
