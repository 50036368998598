export default {
  translation: {
    BackendErrors: {
      category: {
        categoryNotFound: 'Category not found',
        disallowInternalInputField: 'Internal input field cannot be added to the category',
      },
      changesWereNotSaved: {
        unknownError: 'The changes were not saved due to unknown error. Please try again.',
      },
      company: {
        companyIdentifierExists: 'Such a company identifier already exists',
        countCompanies100: '$t(Warnings.countCompanies100)',
      },
      contact: {
        contactAlreadyExists: 'Please, enter another email because contact already exists with the same email',
      },
      digitalSignature: {
        sendMails: {
          resendPasswordMail: 'Password couldn`t send to signee.',
          sendCompanySigneesStartMail:
            'Not all client signees were notified successfully about digital signature start. Following signees have no notification: {{failedCompanySignees}} (E-Mails can be send manually over the digital signature card in the shopping cart view)',
          sendNewLinkMail:
            'New link successfully generated, but couldn`t send successfully email with link to signee. (E-Mail can be send manually over the digital signature card in the shopping cart view)',
          sendNewPasswordMail:
            'New password successfully generated, but couldn`t send successfully email with password to signee. (E-Mail can be send manually over the digital signature card in the shopping cart view)',
          sendPasswordMail:
            "Some passwords could't be sent by mail. The passwords for following signees could't be sent: {{failedPasswordMailRecipients}} (E-Mails can be send manually over the digital signature card in the shopping cart view)",
          sendReminderMail: 'Reminder couldn`t send to signee.',
          sendRevokeNotificationMail:
            'Some signees couldn`t be notified about the revoke of the digital signature process of "{{projectName}}". Following signees have no notification: {{failedCompanySignees}}',
          sendStartMails: 'Digital Signature activated for "{{projectName}}".',
          sendTenantSigneesStartMail:
            'Not all tenant signees were notified successfully about digital signature start. Following signees have no notification: {{failedTenantSignees}} (E-Mails can be send manually over the digital signature card in the shopping cart view)',
        },
        startingProcessFailed: 'The signature process could not be started',
      },
      discount: {
        notFound: 'Discount not found',
      },
      documentTemplate: {
        documentTemplateDeleted: 'Document template not found',
        documentTemplateIsUsed: 'Document template is used in existing shopping carts',
      },
      input: {
        notFound: 'Input not found',
      },
      item: {
        notFound: 'Item not found',
      },
      permissionDenied: 'Permission denied',
      settings: {
        attachmentsSizeLimit:
          'Please, enter another attachments size limit because attachments size limit exceeded range',
      },
      shoppingCart: {
        monthlyPaymentDecisionDone:
          'A previous signee has already made a selection. You can check the selection and if you disagree or find a mistake, then you can contact your tenant to inform and exchange about the next steps.',
        notFound: 'Shopping cart not found',
        sendEmailFailedPdfGenerationFailed:
          'The email could not be sent because at least one document could not be generated.',
      },
      signees: {
        notValid: 'Signees are not valid! (Contact/User does not exist or have no email address)',
      },
      staticItem: {
        notFound: 'Static item not found',
      },
      status: {
        removeDefaultStatus:
          'Impossible to delete the default status. If you want to delete the status, then set another one to default firstly and then try again',
      },
      success: {
        digitalSignature: {
          resendPasswordMail: 'Password sended successfully.',
          sendCompanySigneesStartMail: 'Client signees are notified successfully.',
          sendNewLinkMail: 'New link successfully generated and send to signee.',
          sendNewPasswordMail: 'New password successfully generated and send to signee.',
          sendPasswordMail: 'Passwords are sent successfully.',
          sendReminderMail: 'Reminder send successfully to signee.',
          sendRevokeNotificationMail: 'Signees are notified successfully about the revoke.',
          sendStartMails:
            'Digital Signature started successfully for "{{projectName}}". All signees are successfully notified and got the required access data.',
          sendTenantSigneesStartMail: 'Tenant signees are notified successfully.',
          starting: 'Digital Signature is starting...',
        },
      },
      usedInFormula: {
        calculationMode: '$t(common.Item.switchToOnActualCost)',
        category: 'of category',
        changePaymentInterval:
          'You can NOT set this item payment interval to one-off because it is the last monthly/yearly item in this category and the referred category is used in the pricing-formula of item {{items}}',
        changePaymentInterval_plural:
          'You can NOT set this item payment interval to one-off because it is the last monthly/yearly item in this category and the referred category is used in the pricing-formula of items {{items}}',
        delete: 'Item can NOT be deleted because it is used in pricing formula of item {{items}}',
        deleteInput: 'Input field can NOT be deleted because it is used in pricing formula of item {{items}}',
        deleteLastItem:
          'You can NOT delete the last yearly or monthly payment interval item because it is used in the existing category and the category is referred used in the pricing-formula of item {{items}}',
        deleteLastItem_plural:
          'You can NOT delete the last yearly or monthly payment interval item because it is used in the existing category and the category is referred used in the pricing-formulas of items {{items}}',
        delete_plural: 'Item can NOT be deleted because it is used in pricingFormula of items {{items}}',
        inactive: 'You can NOT set this item inactive because it is used in the pricing-formula of item {{items}}',
        inactiveCategory:
          'You can NOT set this item inactive because it is the last active item in this category and the category is referred used in the pricing-formula of item {{items}}',
        inactiveCategory_plural:
          'You can NOT set this item inactive because it is the last active item in this category and the category is referred used in the pricing-formulas of items {{items}}',
        inactiveUsedItems:
          'You can NOT activate this item because the item {{items}} are used in its pricing formula and are inactive. Please active the item and try again.',
        inactiveUsedItems_plural:
          'You can NOT activate this item because the items {{items}} are used in its pricing formula and are inactive. Please active the items and try again.',
        inactive_plural:
          'You can NOT set this item inactive because it is used in the pricing-formulas of items {{items}}',
        usedVariableInFormula: {
          emailSignature: 'Variable is used in email signature',
          emailTemplates_plural: 'Variable is used in subject or body of e-mail templates {{emailTemplates}}',
          items_plural: 'Variable is used in pricing formula of items {{items}} ',
          staticItems_plural: 'Variable is used in static items of categories {{staticItemCategories}}',
        },
      },
      user: {
        noValidToken: 'Not valid token',
        passwordIsNotValidOrExpired: 'One-time password is not valid or expired. Please try again',
        resetTokenNotValid:
          'The used code is not valid. Codes, that are older then 10 minutes, are automatically not valid. Please restart the password forgot process to get a new code.',
        tenantDisabled: 'You account is inactive - please contact our team',
        userExists: 'Please, enter another email because user already exists with the same email',
        userHasNoPasswordSet: 'User has no password set',
        userNotFound: 'User not found',
        userNotFoundForEmail: 'User not found for email {{email}}',
      },
      variable: {
        duplicated: 'Variable with the same name already exists',
        outsideRange:
          'Variable can needs to be within {{largestMinValue}} and {{lowestMaxValue}} as it is used as default for internal input field of item(s) {{itemNames}}',
      },
    },
    BackendWarnings: {
      item: {
        removedFailedMentions: 'Some placeholders have been invalid due to copy & paste. We replaced those by 0',
        removedFailedMentionsFromFormula: '$t(BackendWarnings.item.removedFailedMentions)',
      },
    },
    CurrentUserDropdown: {
      locale: 'Locale: <1>$t(locale)</1>',
      loggedInAs: 'Logged in as <1>{{name}}</1>',
      logout: 'Logout',
      noUserName: 'No name',
      role: 'Role: <1>{{role}}</1>',
    },
    DashboardPage: {
      HelperWidget: {
        howUseButton: '$t(common.howUseButton)',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'How to use dashboard',
          videoCaption: '',
          videoUrl: 'https://vimeo.com/163721649',
        },
      },
      IntroductionHelperWidget: {
        howUseButton: 'Watch 5 Minute introduction video',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Introduction video',
          videoCaption: '',
          videoUrl: 'https://vimeo.com/163721649',
        },
      },
      actionsBlock: {
        configureOurCatalogue: 'Configure our Catalogue',
        createNewShoppingCart: 'Create a new shopping-cart entry',
        manageShoppingCarts: 'Manage Shopping Carts',
        title: 'What would you like to do?',
      },
      digitalSignature: {
        noActiveSigningProcesses: 'No active signing processes',
        table: {
          linksHeader: 'Links',
          nameOfClientHeader: 'Company',
          openShoppingCart: 'Open shopping cart',
          projectNameHeader: 'Project',
          signShoppingCart: 'Sign shopping cart',
        },
        title: 'Open signature operations where I still need to sign',
      },
      helpCard: {
        book: {
          calendly: 'Book 15 minute onboarding session',
          calendlyUrl: '$t(common.urls.calendlyUrl)',
          sendEmail: 'Send e-mail',
          sendEmailUrl: '$t(common.urls.sendEmailUrl)',
          title: 'Do you still have questions? The please book a session with us',
        },
        title: 'Need help?',
        tutorial: {
          title: 'Watch Tutorial-Video',
        },
      },
      onboardingChecklist: {
        infoAlert: {
          description:
            'If you are using KanzleiPilot as an administrator for the first time, we recommend the following steps:',
          title: 'Tip for easy set-up',
        },
        step: 'Step',
        step1: {
          description: 'Get to know KanzleiPilot',
          modalInfo: {
            title: '5 minute tour',
            videoUrl: 'https://vimeo.com/541269034',
          },
          title: 'Look the 5 minute demo',
        },
        step2: {
          description: 'Send invite',
          modalInfo: {
            title: 'How to invite user',
            videoUrl: 'https://vimeo.com/541649035',
          },
          title: 'Invite user',
        },
        step3: {
          description: 'Do the following',
          modalInfo: {
            title: 'How to create a shopping cart',
            videoUrl: 'https://vimeo.com/541665850',
          },
          title: 'Create first shopping cart',
        },
        step4: {
          description: 'Do it',
          modalInfo: {
            title: 'How to edit catalogue',
            videoUrl: 'https://vimeo.com/541664949',
          },
          step: '',
          title: 'Edit your catalogue',
        },
        step5: {
          description: 'Edit them',
          modalInfo: {
            title: 'How to edit documents',
            videoUrl: 'https://vimeo.com/541664771',
          },
          title: 'Edit documents',
        },
        title: 'Step by step instructions for law firm owners and administrators',
      },
      title: 'Dashboard',
    },
    FooterComponent: {
      copyright: '© KanzleiPilot {{year}}',
    },
    FormulaMentions: {
      RVG_Value_Fee: 'RVG_Wertgebühr',
    },
    FormulaNames: {
      category: 'Monthly fixed value of category "{{name}}"',
      inputField: 'Input field "{{name}}" of category "{{categoryName}}"',
      inputFieldItem: 'Input field "{{name}}" of item "{{categoryName}}"',
      inputField_internal: 'Internal Input "{{name}}" ({{default}})',
      item: 'Item "{{name}}" of category "{{categoryName}}"',
      staticItem: 'Static item "{{name}}" of category "{{categoryName}}"',
      variable: 'Value of variable "{{name}}"',
    },
    InputErrors: {
      phoneNumber: {
        noCode: 'Code is a required field',
        noPhone: 'Phone is a required field',
      },
    },
    Login: {
      inputs: {
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
        password: {
          error: 'Password is incorrect',
          label: 'Password',
          placeholder: 'Password',
        },
      },
      resetPassword: 'I forgot my password',
      submit: 'Submit',
      title: 'Login',
    },
    NavBar: {
      accountSetting: {
        title: '$t(user.Setting.AccountSetting.title)',
      },
      catalogueConfiguration: {
        title: '$t(admin.CatalogueConfiguration.title) ',
      },
      commonLibrary: {
        catalogueCommonLibrary: {
          title: 'Common library catalogue configuration',
          titleSort: 'Catalogue configuration',
        },
        documentCommonLibrary: {
          title: 'Common library document templates',
          titleSort: 'Document templates',
        },
        emailCommonLibrary: {
          title: 'Common library email templates',
          titleSort: 'Email templates',
        },
        title: 'Super-admin settings',
      },
      companyTenant: {
        title: '$t(admin.Setting.TenantCompany.title)',
      },
      constantsAndPlaceholders: {
        title: '$t(admin.ConstantsAndPlaceholders.title)',
      },
      corporateIdentity: {
        title: '$t(admin.Setting.CorporateIdentity.title)',
      },
      createShoppingCart: {
        title: '$t(ShoppingCartEntriesPage.createShoppingCart)',
        tooltip: '$t(ShoppingCartEntriesPage.createShoppingCart)',
      },
      dashboard: {
        title: '$t(DashboardPage.title)',
        tooltip: '$t(DashboardPage.title)',
      },
      digitalSignaturePreferences: {
        title: 'Digital Signature',
      },
      documentTemplateListPage: {
        title: '$t(admin.DocumentTemplateListPage.title)',
      },
      emailSettings: {
        title: '$t(admin.Setting.Email.title)',
      },
      emailTemplates: {
        title: '$t(admin.EmailTemplates.title)',
      },
      importCompanies: {
        title: 'Import companies',
      },
      licenseOverview: {
        title: '$t(admin.Setting.LicenseOverview.title)',
      },
      logo: {
        tooltip: 'Logo',
      },
      manageAllUsers: {
        title: '$t(superAdmin.ManageAllUsers.title)',
      },
      manageDigitalSignature: {
        title: 'Manage Digital Signature Globally',
      },
      managePersons: {
        title: '$t(admin.ManagePersons.title)',
      },
      manageShoppingCartStatus: {
        title: '$t(admin.ManageShoppingCartStatus.title)',
      },
      manageTenants: {
        title: '$t(superAdmin.ManageTenants.title)',
      },
      manageUsers: {
        title: '$t(admin.ManageUsers.title)',
      },
      monthlyPaymentPreferences: {
        title: 'Monthly Payment',
      },
      otherSettings: {
        title: '$t(admin.OtherSettings.title)',
      },
      ourCompany: {
        title: 'Our Company',
      },
      settingTitle: 'Setting',
      shoppingCart: {
        title: 'Shopping Cart',
      },
      shoppingCartEntries: {
        title: '$t(ShoppingCartEntriesPage.title)',
        tooltip: '$t(ShoppingCartEntriesPage.title)',
      },
      shoppingCartPreferences: {
        title: '$t(admin.Setting.ShoppingCartPreferences.title) ',
      },
      warnings: {
        title: '$t(Warnings.title)',
        tooltip: '$t(Warnings.tooltip)',
      },
    },
    PdfDrawer: {
      closeButton: 'Close',
      downloadButton: 'Download',
      onErrorAdditionalText:
        'Try to reload the page. When the error still occures, then try it later a next time and contact your tenant about the error.',
      onLoadingDocument: 'Document is loading...',
      printButton: 'Print',
    },
    ResetPassword: {
      buttons: {
        changePassword: 'Change password',
        goToLogin: 'Go back to login',
        verify: 'Verify',
      },
      enterEmail: "Please enter the email address you'd like your password reset information sent to.",
      errors: {
        noMatch: 'Passwords must match',
      },
      fields: {
        email: 'Email',
        newPassword: 'New password',
        otp: 'One Time Password',
        passwordConfirmation: 'Password confirmation',
      },
      steps: {
        email: 'Enter email',
        setNewPassword: 'Set new password',
        verification: 'Enter one time password',
      },
      title: 'Reset Password',
      verificationDescription:
        'A verification message has been sent to your email address {{email}}. Enter the verification code for the otp input. If the message does not arrive within 5 minutes, you can resend it.',
    },
    ShoppingCartEntriesPage: {
      HelperWidget: {
        howUseButton: '$t(common.howUseButton)',
        modalInfo: {
          helpText: '',
          imageUrl: '/cdn/page-previews/shopping-cart-entries.png',
          title: 'How to use shopping cart entries',
          videoCaption: '',
          videoUrl: 'https://vimeo.com/163721649',
        },
      },
      additionalFilter: {
        digitalSignatureState: {
          DRAFT: 'draft',
          NO_DIGITAL_SIGNATURE: 'no digital signature',
          REVOKED: 'revoked',
          SIGNED: 'signed',
          STARTED: 'started',
        },
        digitalSignatureStateFilterLabel: 'Filter for digital signature state:',
        header: 'More filters',
      },
      catalogue_configuration_button: 'Catalogue configuration',
      createShoppingCart: 'Create a shopping cart',
      digitalSignatureFilterLabel: 'Digital Signature status',
      entriesTable: {
        columnTitles: {
          actions: 'Actions',
          companyIdentifier: 'Company Identifier',
          companyName: 'Company name',
          contact: 'Contact',
          createdAt: 'Created at',
          digitalSignatureState: 'Digital Signature State',
          name: 'Project',
          relatedContacts: 'Related contact(s)',
          status: 'Status',
          valueMonthly: 'Value Monthly',
          valueOneOff: 'Value One-Off',
          valueYearly: 'Value Yearly',
        },
        digitalSignatureState: {
          DRAFT: 'draft',
          NO_DIGITAL_SIGNATURE: 'no digital signature',
          REVOKED: 'revoked',
          SIGNED: 'signed',
          STARTED: 'started',
        },
      },
      menuCartFilterLabel: 'Shopping Cart status',
      noStatus: 'No status',
      search: 'Filter shopping carts by typing in your company identifier, company name, or related contacts',
      searchTitle: 'Search shopping carts',
      statusAll: 'All',
      statusModal: {
        placeholder: 'Select existing status',
        title: 'Edit status of shopping cart',
      },
      title: 'Shopping cart entries',
    },
    Warnings: {
      countCompanies100:
        'You can NOT create any more companies because you reached your limit. Please contact us via <a>support@kanzleipilot.de</a> to increase your company limit.',
      countCompanies80:
        'Number of companies exceeded 80%. Please contact us via <a>support@kanzleipilot.de</a> to increase your company limit.',
      countCompanies90:
        'Number of companies exceeded 90%. Please contact us via <a>support@kanzleipilot.de</a> to increase your company limit.',
      countUsers100:
        'You can NOT create any more users because you reached your limit. Please contact us via <a>support@kanzleipilot.de</a> to increase your user limit.',
      countUsers80:
        'Number of users exceeded 80%. Please contact us via <a>support@kanzleipilot.de</a> to increase your user limit.',
      countUsers90:
        'Number of users exceeded 90%. Please contact us via <a>support@kanzleipilot.de</a> to increase your user limit.',
      title: 'Warnings',
      tooltip: 'Warnings',
    },
    admin: {
      CatalogueConfiguration: {
        ConditionalVisibilityHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/conditional-visibility.png',
            title: 'How to use category conditional visibility',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        ConfigureCategoryProperties: {
          properties: {
            conditionalVisibility: {
              bodyTitle: 'Show this category (in shopping cart) if companyBookingType equals:',
              title: 'Conditional Visibility',
            },
            discounts: {
              title: 'Discounts',
            },
            inputFields: {
              title: 'Input Fields',
            },
            items: {
              title: 'Items',
            },
            staticItems: {
              title: 'Static Items',
            },
            texts: {
              title: 'Texts',
            },
          },
          title: 'Configure category properties',
        },
        DiscountsHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use category discounts',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        EditCategoryHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to edit category',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        EmailHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Learn about e-mail settings',
            videoCaption: 'This is how you configure email',
            videoUrl: 'https://vimeo.com/563388433',
          },
        },
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/catalogue-configuration.png',
            title: 'How to use catalogue configuration',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        ImportHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/import-categories.png',
            title: 'How to use import categories from common library',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        InputFieldsHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use category input fields',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        StaticItemsHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/static-items.png',
            title: 'How to use static items',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        StaticItemsTab: {
          inputs: {
            intro: {
              label: 'Intro-text',
              tooltip: 'This text appears above list of static items of this category',
            },
            outro: {
              label: 'Outro-text',
              tooltip: 'This text appears below list of static items of this category',
            },
          },
        },
        TextsHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use category texts',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        addCategory: 'Add category',
        collapseOptions: {
          duplicate: 'Duplicate category',
          edit: 'Edit category',
          remove: 'Remove category',
        },
        copyItem: {
          addonAfter: '(copy)',
          cancel: '$t(common.cancel)',
          complexPricingFormula:
            '[{"type":"paragraph","children":[{"text":"The item or item\'s comboboxes contain a \\"complex\\" pricing formula. We can copy it but with some restrictions: "}]},{"type":"bulleted-list","listType":"disc","children":[{"type":"list-item","children":[{"text":"references to other categories will be replaced by 1 (in the copy)"}]},{"type":"list-item","children":[{"text":"references to other items will be replaced by 1 (in the copy)"}]},{"type":"list-item","children":[{"text":"references to category-input fields will be replaced by 1 (in the copy)"}]}]},{"type":"paragraph","children":[{"text":"This simply means that you need to adjust the pricing formula afterwards in the copy."}]}]',
          duplicatedErrorMessage: 'Item names are unique. Please enter the new name',
          inputs: {
            category: {
              errorLabel: 'Category',
              label: 'Select your category',
            },
            name: {
              label: '$t(common.fields.name.label)',
            },
          },
          ok: 'Copy item',
          openModalButtonTitle: 'Copy to different category',
          singleImport: 'single import',
          success: {
            subTitle: 'We successfully imported the item',
            title: 'Success',
          },
          title: 'Which category do you want to transport to',
        },
        discounts: {
          title: 'Discounts',
        },
        emptyCategoryListMessage: 'There are no categories yet. Create one by clicking the "Add category" button',
        emptyDiscountListMessage: 'There are no discounts yet. Create one by clicking the "Add discount" button',
        emptyInputFieldListMessage:
          'There are no input fields yet. Create one by clicking the "Add input field" button',
        emptyInputFieldOptionListMessage:
          'There are no input field options yet. Create one by clicking the "Add option" button',
        emptyItemListMessage: 'There are no items yet. Create one by clicking the "Add item" button',
        emptyStaticItemListMessage:
          'There are no static items yet. Create one by clicking the "Add static item" button',
        importCategoryPresets: 'Import category-presets',
        itemsValidation: {
          button: 'Check all item pricing formula',
          error: '{{itemName}} item has an invalid formula in the {{categoryName}} category',
          success: 'There are no errors in the pricing formulas',
        },
        title: 'Catalogue configuration',
        titleCommonLibrary: 'Common library',
      },
      ConstantsAndPlaceholders: {
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use constants and placeholders',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        addVariableButton: 'Add variable',
        addVariableModalTitle: 'Create a new variable',
        columns: {
          description: 'Description',
          mandatory: 'Mandatory',
          name: 'Name',
          type: 'Type',
          value: '$t(common.fields.value.label)',
        },
        editVariableModalTitle: 'Edit variable',
        fields: {
          description: {
            label: '$t(common.fields.description.label)',
          },
          name: {
            label: '$t(common.fields.name.label)',
          },
          type: {
            label: '$t(common.fields.type.label)',
          },
          value: {
            label: '$t(common.fields.value.label)',
          },
        },
        title: 'Constants and Placeholders',
        variableListTitle: 'Constants and Placeholders List',
      },
      DocumentTemplateConfigurationPage: {
        AddBlockHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to add block',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        BlockHelperWidget: {
          howUseButton: 'Instructions: {{displayName}} set up',
          modalInfo: {
            FreeText: {
              title: 'How to set up the Free Text block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            FullPageImage: {
              title: 'How to set up the Full Page Image',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/586189327',
            },
            Introduction: {
              title: 'How to set up the Introduction block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            Investment: {
              title: 'How to set up the Investment block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            ListBookmarkedItems: {
              title: 'How to set up the List of Bookmarked Items block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            NextSteps: {
              title: 'How to set up the Next Steps block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            ServiceCatalogue: {
              title: 'How to set up the Service Catalogue block',
              videoCaption: 'Learn how to use the Service Catalogue block',
              videoUrl: 'https://vimeo.com/527775922',
            },
            ServiceDetails: {
              title: 'How to set up the Service Details block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            ServiceDetailsForContract: {
              title: 'How to set up the Service Details for Contract block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            Signatures: {
              title: 'How to set up the Signatures block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            SpecialAgreement: {
              title: 'How to set up the Special Agreement Items block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            TableOfContent: {
              title: 'How to set up the Table of Content block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            Testimonial: {
              title: 'How to set up the Testimonial block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            Title: {
              title: 'How to set up the Title block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            helpText: '',
          },
        },
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/edit-document-template.png',
            title: 'How to use edit document template',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        addBlockModal: {
          addToBottomButton: 'add to bottom',
          addToTopButton: 'add to top',
          availableBlocksTitle: 'Available Blocks',
          blockActionsColumnTitle: 'Actions',
          blockNameColumnTitle: 'Name',
          blockPreviewTitle: 'Block Preview',
          title: 'Add a block to document template',
        },
        backTitle: 'Back to Document Templates',
        blockSubtitles: {
          activeDigitalSignature: 'When Digitale Signatur is active',
          catalogue: 'Catalogue Settings',
          category: 'Category Level:',
          decided: 'Decision made',
          decissionSection: 'Decision section',
          endOfBlock: 'End of block',
          inactiveDigitalSignature: 'When Digitale Signatur is inactive',
          internal: 'Internal settings for Workshops, Onboarding and internal Guidance',
          introductions: 'Introduction texts',
          item: 'Item Level:',
          page: 'Page Settings',
          pricingSection: 'Pricing Section',
        },
        blocks: {
          body: 'Personalized text',
          introductionBlockNumber: '(introduction-block #{{number}})',
        },
        deleteBlockConfirmation: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title:
            'Do you want to remove this block? Warning: your custom configuration for the block (texts, settings, etc.) will be lost.',
        },
        generalInformationSection: {
          descriptionInputLabel: 'Description',
          descriptionInputTooltip:
            'Internal description of the document template. Good to document for your team what exactly this is about.',
          lastUsedDocumentNr: 'Last used number: {{n}}',
          nameInputLabel: 'Name',
          nameInputTooltip:
            'Name of the document template. Appears like this for selection in the interactive menu card.',
          nrOfDocumentsCreated: '{{count}} document created',
          nrOfDocumentsCreated_plural: '{{count}} documents created',
          title: 'General information',
        },
        layoutSection: {
          addBlockButton: 'Add Block',
          emptyBlockList:
            'There are no blocks in your document template. Please use "Add Block" button above to add blocks',
          infoSectionTitle: 'Info',
          title: 'Layout',
          tooltip:
            'The layout of a document template consists of blocks that you can edit here. Click on a block below to customize the texts. Click on "+ Add Block" to add a new block to the layout.',
        },
        title: 'Edit Document Template',
      },
      DocumentTemplateListPage: {
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/document-templates.png',
            title: 'How to use document templates',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        ImportHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/import-document-templates.png',
            title: 'How to use import document templates from common library',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        actions: {
          deleteDocumentTemplate: 'Delete the document template',
          duplicateDocumentTemplate: 'Duplicate document-template',
          editDocumentTemplate: 'Edit the document template',
        },
        addDocumentTemplateButton: 'Add Document Template',
        createdAt: 'Created {{date}}',
        duplicateDocumentTemplateModal: {
          cancel: '$t(common.cancel)',
          duplicatedErrorMessage: 'Document template with the same name already exists',
          ok: '$t(common.ok)',
          title: 'Please enter name for new document-template',
        },
        emptyListMessage:
          'There are no document templates yet. Create one by clicking the "$t(admin.DocumentTemplateListPage.addDocumentTemplateButton)" button',
        errorMessageInModal: 'Categories with such names {{duplicatedDocuments}} have already been added to the list',
        importEmptyListMessage: 'There are no document templates yet',
        importFromLibraryButton: 'Import from library',
        importFromLibraryModalTitle: 'Import Document Templates from Common Library',
        lastUsedDocumentNr: 'Current #:{{n}}',
        nrOfDocumentsCreated: '{{count}} document created',
        nrOfDocumentsCreated_plural: '{{count}} documents created',
        title: 'Document Templates',
        titleCommonLibrary: 'Common Library Document Templates',
      },
      EmailTemplates: {
        add: 'Add email templates',
        columns: {
          actions: '$t(common.columns.actions)',
          body: '$t(common.columns.body)',
          isDefault: 'Is Default',
          name: '$t(common.columns.name)',
          subject: '$t(common.columns.subject)',
        },
        fields: {
          body: {
            label: '$t(common.fields.body.label)',
            placeholder: '$t(common.fields.body.placeholder)',
          },
          isDefault: {
            label: 'Is default',
            no: '$t(common.no)',
            yes: '$t(common.yes)',
          },
          name: {
            label: '$t(common.fields.name.label)',
            placeholder: '$t(common.fields.name.placeholder)',
          },
          shouldSendAttachments: {
            label: 'Send documents as attachments with e-mail',
            off: 'OFF',
            on: 'ON',
          },
          subject: {
            label: '$t(common.fields.subject.label)',
            placeholder: '$t(common.fields.subject.placeholder)',
          },
        },
        import: 'Import from common library',
        importEmptyEmailTemplateListMessage: 'There are no email templates yet.',
        importErrorMessage:
          'Email templates with such names {{duplicatedEmailTemplates}} have already been added to the list',
        importModalTitle: 'Import Email Templates Presets from Common Library',
        modal: {
          addTitle: 'Create a new email template',
          deleteTitle: 'Are you sure you want to remove the email template?',
          editTitle: 'Edit email template',
          error: {
            delete: 'Failed to delete email template',
            duplicatedErrorMessage: 'Email template with the same name already exists',
          },
        },
        title: 'Email Templates',
      },
      ManagePersons: {
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use persons management',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        addPerson: 'Add person',
        addPersonModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Add person',
        },
        cardTitle: 'Person',
        deleteConfirmModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Are you sure you want to remove the person?',
        },
        fields: {
          name: {
            label: '$t(common.fields.name.label)',
            placeholder: '$t(common.fields.name.placeholder)',
          },
          position: {
            label: '$t(common.fields.position.label)',
            placeholder: '$t(common.fields.position.placeholder)',
          },
          signature: {
            label: 'Signature',
            tooltip: 'Note: this signature is only shown in "introduction” block.',
          },
        },
        title: 'Manage person',
        uploadLabel: 'Upload signature image',
      },
      ManageShoppingCartStatus: {
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use statuses management',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        addStatus: 'Add status',
        addStatusModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Create a new status',
        },
        columns: {
          name: '$t(common.columns.name)',
        },
        defaultStatusTitle: 'Select default status',
        fields: {
          name: {
            label: '$t(common.fields.name.label)',
            placeholder: '$t(common.fields.name.placeholder)',
          },
        },
        statusListTitle: 'Status List',
        title: 'Manage Shopping cart status',
        updateStatusModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Update status',
        },
      },
      ManageUsers: {
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use users management',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        buttons: {
          sendInvitation: 'Send Invitation',
        },
        modal: {
          editUser: 'Edit user',
        },
        title: 'Manage users',
        userInvitation: {
          inputs: {
            customSignatureImage: {
              emptyPlaceholder: 'No signature image configured. Use generic one.',
              label: 'Custom signature image (optional):',
              resetButton: 'Reset Signature Image',
              uploadButton: 'Upload Signature Image',
            },
            email: {
              label: 'Email',
            },
            firstName: {
              label: 'First name',
            },
            lastName: {
              label: 'Last name',
            },
            position: {
              label: 'Position',
            },
            role: {
              label: 'Role',
              tooltip:
                'Role and rights of the user. We distinguish between 4 roles: 1) "Users with read-only access" can display menu cards - useful, e.g. for reception. 2) "User (can create menu cards)" can create and edit menu cards (menu cards are test-proof). 3) Administrators have access to settings and can, for example, edit the service catalog. 4) Owners can do anything',
            },
          },
          title: 'Invite new user',
        },
        userList: {
          fields: {
            email: '$t(common.fields.email.label)',
            name: '$t(common.fields.name.label)',
            onboarding: 'Onboarding',
            position: '$t(common.fields.position.label)',
            role: '$t(common.fields.role.label)',
            status: '$t(common.fields.status.label)',
          },
          title: 'List of users',
        },
      },
      OtherSettings: {
        globalSettings: {
          inputs: {
            hideCountryInAddresses: {
              label: 'Hide country for addresses in same country like our country',
            },
          },
          title: 'Global settings',
        },
        title: 'Other settings',
      },
      Placeholders: {
        accountOwnerForSepaMandate: {
          label: 'Account owner name for SEPA mandate',
        },
        allContactsSeparated: {
          label: 'All contacts separated with comma/and',
        },
        allContactsWithFullSalutation: {
          label: 'All contacts with full salutation',
        },
        bankAccountInformations: {
          label: 'Bank informations',
        },
        bicForSepaMandate: {
          label: 'BIC for SEPA mandate',
        },
        categoryTotals: {
          deleted: 'Deleted Category',
          label: 'Category monthly costs: theoretical of certain category',
          mention: 'Category monthly costs: theoretical of category "{{name}}"',
        },
        client: {
          label: 'Client',
        },
        company: {
          label: 'Company',
        },
        currentDate: {
          label: 'Current date',
        },
        digitalSignature: {
          label: 'Digital Signature',
        },
        digitalSignatureState: {
          label: 'Digital Signature Process State',
        },
        document: {
          label: 'Document',
        },
        documentCreatedAt: {
          label: 'Document creation date',
        },
        documentNumber: {
          label: 'Document number',
        },
        effectiveTotalMonthlyFixedPrice: {
          label:
            'Monthly cost: effective (Monthly Payment or Monthly fixed price, monthly values + yearly values / 12 month)',
        },
        effectiveTotalMonthlyFixedPriceDetailed: {
          label:
            'Monthly cost: effective (Monthly Payment or Monthly fixed price, monthly values + yearly values / 12 month) - with additional infos',
        },
        endOfContract: {
          label: 'End of Contract',
        },
        environment: {
          label: 'Environment',
        },
        ibanForSepaMandate: {
          label: 'IBAN for SEPA mandate',
        },
        linkButton: {
          label: 'Button for Signing Process',
        },
        linkExpirationDate: {
          label: 'Deadline for link',
        },
        linkValidityTime: {
          label: 'Left time for link',
        },
        listOfAllNotSignedSignees: {
          label: 'List of signees` names, which have not signed yet',
        },
        listOfAllSignedSignees: {
          label: 'List of signees` names, which have already signed',
        },
        meetingAt: {
          label: 'Meeting Date',
        },
        monthlyPaymentDecision: {
          label: 'Monthly Payment: Decision',
        },
        numberOfReadySignees: {
          label: 'Number of ready signees',
        },
        openedSigneeName: {
          label: 'Name of signee, which opened its signing process currently',
        },
        projectName: {
          label: 'Project name',
        },
        requirePassword: {
          label: 'Password required',
        },
        revokeInternalNote: {
          label: 'Internal note for revoke',
        },
        revokeInternalNoteBox: {
          label: 'Box with internal note for revoke',
        },
        sepa: {
          label: 'SEPA-Mandat',
        },
        sepaMandateText: {
          label: 'Mandate text for SEPA mandate',
        },
        shoppingCart: {
          label: 'Shopping Cart',
        },
        shoppingCartCompanyCity: {
          label: 'Shopping cart company city',
        },
        shoppingCartCompanyCommercialObject: {
          label: 'Commercial object',
        },
        shoppingCartCompanyFullAddress: {
          label: 'Shopping cart company full address',
        },
        shoppingCartCompanyOrFullname: {
          label: 'Shopping cart company name or full name',
        },
        shoppingCartVatType: {
          label: 'Vat wording',
        },
        shoppingCartVatTypeWithPercentage: {
          label: 'VAT Wording info-text with percentage',
        },
        shortenedAccountOwnerForSepaMandate: {
          label: 'Shortened account owner name for SEPA mandate',
        },
        shortenedBicForSepaMandate: {
          label: 'Shortened BIC for SEPA mandate',
        },
        shortenedIbanForSepaMandate: {
          label: 'Shortened IBAN for SEPA mandate',
        },
        signedSigneeName: {
          label: 'Signee`s name, which have currently signed',
        },
        signeeEmail: {
          label: 'Signee`s e-mail address',
        },
        signeeFirstName: {
          label: 'Signee`s firstname',
        },
        signeeLastName: {
          label: 'Signee`s lastname',
        },
        signeeSalutation: {
          label: 'Signee`s full salutation',
        },
        signeeState: {
          label: 'Signee`s state',
        },
        signingPassword: {
          label: 'Password for Signing Process',
        },
        signingUpdates: {
          label: 'Signature process updates',
        },
        startOfContract: {
          label: 'Start of Contract',
        },
        tenantCompany: {
          label: 'Our own company',
        },
        tenantCompanyCity: {
          label: 'Our company city',
        },
        tenantCompanyCityCode: {
          label: 'Our company city code',
        },
        tenantCompanyCountry: {
          label: 'Our company country',
        },
        tenantCompanyCreditorIdentNr: {
          label: 'Creditor identification number',
        },
        tenantCompanyCurrentUserName: {
          label: 'Current user name',
        },
        tenantCompanyFullAddress: {
          label: 'Our company full address',
        },
        tenantCompanyHouseNumber: {
          label: 'Our company house number',
        },
        tenantCompanyName: {
          label: 'Our company name',
        },
        tenantCompanyOfficeEmail: {
          label: 'Our office e-mail',
        },
        tenantCompanyPhoneNumber: {
          label: 'Our company phone number',
        },
        tenantCompanyPositionOfCurrentUserName: {
          label: 'Position of current user name (from his profile)',
        },
        tenantCompanyStreet: {
          label: 'Our company street',
        },
        tenantCompanyWebsite: {
          label: 'Our company website url',
        },
        totalMonthlyFixedPrice: {
          label: 'Monthly cost: theoretical (monthly values + yearly values / 12 month)',
        },
        totalMonthlyFixedPriceDetailed: {
          label: 'Monthly cost: theoretical (monthly values + yearly values / 12 month) - with additional infos',
        },
        totalMonthlyValue: {
          label: 'Total monthly value (pure value)',
        },
        totalMonthlyValueDetailed: {
          label: 'Total monthly value - with detailed explanation',
        },
        totalOneOffValue: {
          label: 'Total one-off value (pure value)',
        },
        totalOneOffValueDetailed: {
          label: 'Total one-off value - with detailed explanation',
        },
        totalYearlyFixedPrice: {
          label: 'Total yearly fixed Price',
        },
        totalYearlyFixedPriceDetailed: {
          label: 'Total yearly fixed price - with detailed explanation',
        },
        totalYearlyValue: {
          label: 'Total yearly value (pure value)',
        },
        totalYearlyValueDetailed: {
          label: 'Total yearly value - with detailed explanation',
        },
        variables: {
          deleted: 'Deleted Variable',
          label: 'Variables',
        },
      },
      RichEditor: {
        toolbar: {
          link: {
            createModalName: 'Link name',
            createModalTitle: 'Add a link',
            createModalValue: 'Link URL',
            modalValueInvalidError: 'Link URL must be a valid URL',
            modalValueTooltipHTML:
              '<div><div>Example:</div><div>https://google.com - insert a web site link</div><div>mailto:someone@gmail.com - insert a create email link</div><div>tel:+49123456789 - insert a phone number</div></div>',
          },
        },
      },
      Setting: {
        CorporateIdentity: {
          BodyPrimary: {
            field: {
              background: 'Background color',
              color: 'Text color',
              fontFamily: 'Font family',
              marginBottom: 'Margin bottom',
              marginTop: 'Margin top',
            },
            inputs: {
              bodyPrimary: 'Body primary',
              bodyPrimaryFooter: 'Body primary footer',
            },
            title: 'Body Primary',
          },
          CorporatePrimaryColor: {
            inputs: {
              color: 'Primary Contrast Color',
              fontFamily: 'Font Family',
              primaryColor: 'Primary Color',
            },
            preview: 'Preview of button (Primary+Contrast)',
            title: 'Corporate Primary Color',
          },
          HelperWidget: {
            howUseButton: '$t(common.howUseButton)',
            modalInfo: {
              helpText: '',
              imageUrl: '/cdn/page-previews/corporate-identity.png',
              title: 'How to use corporate identity',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/163721649',
            },
          },
          TableOfContent: {
            field: {
              background: 'Background color',
              color: 'Text color',
              fontFamily: 'Font family',
              fontSize: 'Font size',
              marginBottom: 'Margin bottom',
              marginTop: 'Margin top',
            },
            title: 'Table of content',
          },
          TableTheme: {
            field: {
              background: 'Background color',
              color: 'Text color',
              fontFamily: 'Font family',
              fontSize: 'Font size',
              marginBottom: 'Margin bottom',
              marginTop: 'Margin top',
            },
            inputs: {
              table: 'Table',
              tableContent: 'Table content',
              tableFooter: 'Table footer',
              tableHeaderPrimary: 'Table header primary',
              tableHeaderSecondary: 'Table header secondary',
              tableLeftColumn: 'Table left column',
            },
            title: 'Styles for Table',
          },
          TenantLogo: {
            bigImage: 'The image is too big, please use image with width and height lower than 1000px',
            logo: 'Logo',
            remove: 'Remove Image',
            select: 'Select Image',
            title: 'Tenant Logo',
          },
          TextStylesForDocuments: {
            field: {
              color: 'Color',
              fontFamily: 'Font',
              fontSize: 'Font Size',
              lineHeight: 'Line Height',
              marginBottom: 'Margin bottom',
              marginTop: 'Margin top',
            },
            inputs: {
              h1: 'H1',
              h2: 'H2',
              h3: 'H3',
              testimonial: 'Testimonial',
              testimonialSmallText: 'Testimonial small text',
              textBlock: 'Text-block',
              textLessImportant: 'Text color (less important)',
              title: 'Title',
            },
            title: 'Text Styles for Documents',
          },
          confirmSubmit: 'New colour is gonna be set. Continue?',
          customize: 'Customize in more detail',
          errors: {
            reset: 'Oops. There was a problem resetting the theme',
          },
          inputs: {
            font: {
              placeholder: 'Select existing font',
            },
          },
          reset: 'Reset',
          resetConfirmModalMessage:
            'Danger: Are you sure you want to reset CI to KanzleiPilots defaults? You will loose your customized settings! Continue?',
          title: 'Corporate Primary Colour',
        },
        DigitalSignaturePreferences: {
          additionalNotificationSettings: {
            cardLabel: 'Additional notification settings',
            notifySigneesAboutSignedBySignee: {
              label: 'Should signees get notified by email, if an other signee signs its signing process?',
              tooltip:
                'Here you can configure, if other signees of a digital signature process should be notified by email, if a signee signs its signing process. You can select the used email template in the next option.',
            },
            notifySigneesAboutSignedBySigneeTemplate: {
              label:
                'Which email template would you like to use to notify signees about the event, that an other signee signed its signing process?',
              placeholder: 'No template selected',
              tooltip:
                'Here you can select the email template for notifing other signees of a digital signature process, that a signee signed its signing process. You can use placeholders to add the name of the signee, name of all signees, which have signed and the names of signees, which haven`t signed yet',
            },
            notifyTenantSigneesAboutOpenBySignee: {
              label: 'Should tenant signees be notified by email, if a signee opens its signing process?',
              tooltip:
                'Here you can enable if tenant signees of a digital signature process get a notification email, if a signee opens its signing process. In the next option you can select the used email template for it.',
            },
            notifyTenantSigneesAboutOpenBySigneeTemplate: {
              label:
                'Which email template would you like to use to notify tenant signees about an opened signing process?',
              placeholder: 'No template selected',
              tooltip:
                'Here you can configure, which email template should be used to notify tenant signees about an opened signing process. You can use placeholders in the email template to insert the name of the signee into the email.',
            },
          },
          automateSignatureProcess: {
            automaticRemindSigneesAboutSignatureProcess: {
              label: 'Should signees be reminded automatically by the system, if they have an open signing process?',
              tooltip:
                'Here you can configure, if signees with an open signing process should be automatically reminded by email perodically. You can configure the interval in the next option. The system uses the email template configured as reminder email template to notify the signees. This will be applied on all active signature processes immediately.',
            },
            automaticRemindSigneesAboutSignatureProcessInterval: {
              label: 'How long should be the interval between to reminder emails in days?',
              tooltip:
                'Here you can configure the reminder interval in days. The first reminder email will be sent after this interval. The next reminders get be sent perodically in this interval. This will be applied on all active signature processes immediately.',
              unit: 'Days',
            },
            cardLabel: 'Automate Signature Process',
            notifySigneesForRevokeTemplate: {
              label:
                'Which email template should be used for notifing the signees about a revoked Digital Signature Process?',
              placeholder: 'No template selected',
              tooltip:
                'Here you can configure, which email template should be used for notifing the signees about a cancel Digital Signature Process. With this template all signees will be notified, after a Digital Signature Process was cancelled and the function to notify the signees was enabled during the revoking of the Digital Signature Process.',
            },
            notifySigneesForSuccessfulFinishTemplate: {
              label:
                'Which email template should be used for notifing the signees about a successful finished Digital Signature Process?',
              placeholder: 'No template selected',
              tooltip:
                'Here you can configure, which email template should be used for notifing the signees about a successful end of a Digital Signature Process. Check, if the sending of documents as attachment is enabled for the template. With this template all signees will be notified and the signed documents will be sent, after all signees have successful signed.',
            },
            postSuccessfulFinishState: {
              label: 'Which state should the shopping cart have after a successful Digital Signature Process?',
              placeholder: 'Leave current state',
              tooltip:
                'Here you can configure, if the state of a shopping cart should be changed and which state should be the new one after successful finishing the Digital Signature Process. With the option “Don’t change state” the current state of the shopping cart won’t be changed.',
            },
            remindSigneeAboutDigitalSignatureProcessTemplate: {
              label:
                'Which email template would you like to use for reminding signees about a running Digital Signature Process?',
              placeholder: 'No template selected',
              tooltip:
                'Here you can configure, which email template should be used for reminding signees about a running digital signature process automatically and manually. Check, if the used template contains a button placeholder for the signing link, elseway the current signing link for the signee won’t be part of the email. It will always be used the template, which was configured at the start of the signature process.',
            },
          },
          generalSettings: {
            autoSignForTenantSigneesEnabled: {
              label: 'Should be automatically signed for tenant team?',
              tooltip:
                'Here you can configure, if the system should automatically sign for your users and you. It is recommended to ask your users, if that is suitable for them. If this option is enabled, then the system will sign for each selected tenant signee automatically and the signee doesn’t have to do anything for the Digital Signature Process anymore.',
            },
            cardLabel: 'General',
            defaultLinkValidityTime: {
              label: 'How long should the link for the signing process be valid by default?',
              tooltip:
                'Here you can configure, how much time a signee has to use his signing link. The signee can’t access his signing process, if he doesn’t signed yet and no time is left. You can configure manually the time for each signee in the shopping cart view, if the signee needs more time.',
              unit: 'Hours',
            },
            defaultTenantSignees: {
              label: 'Who should be preselected to sign for the tenant?',
              placeholder: 'No signee preselected',
              specialOption: 'Current Creator',
              tooltip:
                'Here you can configure, who should be preselected as signee for your company. You can select multiple users. You can also select the option “Current Creator”. Then the user, which creates or edits the shopping cart, will be preselected automatically. You can add more signees or remove signees in the shopping cart.',
            },
            digitalSignatureEnabled: {
              disabledInfo: 'This feature is disabled on platform level and can`t be used.',
              label: 'Enable Digital Signature for your company',
              tooltip:
                'Here you can decide, if you want to use Digital Signature for your company. You don’t need to let sign all shopping carts digital, if you have enabled this option. You can decide for each shopping cart individual, if you want to use digital signature or not. If you switch this option off, you don’t have the possibility to use digital signature.',
              warning: {
                description:
                  'You try to switch the digital signature off. Please consider, if you save the settings with digital signature disabled, then all ongoing digital signature process will be cancelled.',
                title: 'Warning',
              },
            },
            notifyClientSigneesForDigitalSignatureStartTemplate: {
              label:
                'Which email template would you like to use for notifing company signees about the start of the digital signature process?',
              placeholder: 'No template selected',
              tooltip:
                'Here you can configure, which email template should be used for notifing company signees about the start of a Digital Signature Process. Check, if the used template contains a button placeholder for the signing link.',
            },
            notifyTenantSigneesForDigitalSignatureStartTemplate: {
              label:
                'Which email template would you like to use for notifing tenant team about the start of the digital signature process?',
              placeholder: 'No template selected',
              tooltip:
                'Here you can configure, which email template should be used for notifing tenant signees about the start of a Digital Signature Process. Check, if the used template contains a button placeholder for the signing link.',
            },
            notifyUsersByMail: {
              label: 'Should the tenant team be notified by email about the start of a Digital Signature Process?',
              tooltip:
                'Here you can select, if your users of your company and you should be notified by email about the start of a Digital Signature Process, in which they are part of. Users can access their signature processes over KanzleiPilot, if they are logged in. If this option is disabled, then your users and you don’t get notified about the start of a Digital Signature Process by email.',
            },
            passwordEnabled: {
              label: 'Should the signing process be secured by passwords?',
              tooltip:
                'Here you can configure, if the signing process for the signees should be secured by a password. It is recommended to enable this option. The password will be generated automatically and sent by email to the signees.',
            },
            sendPasswordToSigneesTemplate: {
              label: 'Which email template would you like to use for sending the password towards the signees?',
              placeholder: 'No template selected',
              tooltip:
                'Here you can configure, which email template should be used for sending the password for the signing process of a signee. Check, if the used email template contains a placeholder for the password (The placeholder can be found in the notification group). The signees won’t get their password by email, if the email template doesn’t contain a password placeholder. You can fetch the password for each signee manually in the shopping cart view and give the password over an other way to the signee.',
            },
          },
          helperWidget: {
            howUseButton: 'Tutorial: Configure Digital Signature',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'Tutorial, how to configure the Digital Signature',
              videoCaption: 'Get more informations about the Digital Signature',
              videoUrl: 'https://vimeo.com/862392827',
            },
          },
          importDefaultTemplatesButton: 'Import default templates for digital signature',
          importDefaultTemplatesModal: {
            cancelButton: 'Cancel',
            importAllTemplatesButton: 'Reimport all default templates and replace old',
            importError: 'The import of the default templates failed. Please try again.',
            importMissingTemplatesButton: 'Import missing default templates',
            importTemplatesButton: 'Import default templates',
            message: {
              cleanImport: 'Would you like to import default templates for digital signature from common library?',
              incompleteImport:
                'Would you like to import the missing default templates for digital signature from the common library or reimport all templates and replace the old ones?',
              reimportAll:
                'Would you like to reimport the default templates for digital signature? The existing ones will be replaced by the imported ones. Or would you like to set the imported templates as selection in the settings?',
            },
            setSettingsButton: 'Set imported templates as selection in settings',
            shouldSetSelectionValuesToImportedTemplatesSwitch: {
              label: 'Would you like to set the imported templates as selection in the settings?',
            },
            title: 'Import default templates for digital signature',
          },
          noticeOfCancellation: {
            cardLabel: 'Notice of cancellation',
            noticeOfCancellationDocumentTemplate: {
              label:
                'Which document template would you like to use for the notice of cancellation for private persons?',
              placeholder: 'No template selected',
              tooltip:
                'Here you can configure, which document template should be used for the notice of cancellation for private persons. This document will be added automatically to the Digital Signature Process, if the client is a private person. In this case, the signees on client side have to confirm, that they have read the notice of cancellation and that they agree to it.',
            },
          },
          resetButton: 'Reset digital signature preferences',
          resetConfirm:
            'Danger: Are you sure you want to reset digital signature preferences to KanzleiPilots defaults? You will loose your customized settings! Continue?',
          sepa: {
            cardLabel: 'SEPA',
            documentTemplateSelect: {
              label: 'Which document template should be used for the SEPA mandate?',
              placeholder: 'No template selected',
              tooltip:
                'Here you can select the document template for the SEPA mandate. This will be added automatically to the shopping cart, if the client grants the mandate. You can add the bank account informations and the mandate text by placeholders into the template.',
            },
            introductionText: {
              label: 'Which intro text would you like to show for the request of the SEPA mandate?',
              placeholder: 'Intro text',
              tooltip: 'Here you can configure the intro text for the SEPA mandate step in the signing process.',
            },
            mandateCompany: {
              label: 'Which text should be used for the SEPA mandate for companies?',
              placeholder: 'Mandate text for companies',
              tooltip:
                'Here you can configure the text for the SEPA mandate for companies. The text is shown in the SEPA mandate step as well as provided as placeholder for the SEPA mandate document. Both options take the text for private persons or for companies by client type.',
            },
            mandatePrivate: {
              label: 'Which text should be used for the SEPA mandate for private persons?',
              placeholder: 'Mandate text for private persons',
              tooltip:
                'Here you can configure the text for the SEPA mandate for private persons. The text is shown in the SEPA mandate step as well as provided as placeholder for the SEPA mandate document. Both options take the text for private persons or for companies by client type.',
            },
            sepaEnables: {
              label: 'Would you like to ask the client for a SEPA mandate in the signing process?',
              tooltip:
                'Here you can configure, if you want to ask the client for a SEPA mandate during the signing process. When this option is enabled, then the first signee of the client will be asked for the data of the SEPA mandate and if the signee agrees to give you the SEPA mandate, then a document for the SEPA mandate will be added to the signing process',
            },
            sepaMode: {
              label: 'Should the SEPA mandate be optional or mandatory for the client?',
              mandatory: 'Mandatory',
              optional: 'Optional',
              tooltip:
                'Here you can configure, if the client can decide, if the client wants to grant a SEPA mandate or doesn’t want to grant it. If this option is on ‘”Mandatory”, then the client have to give the bank account informations for the SEPA mandate to continue in the signing process. If this option is on “Optional”, then the client can decide, if the client wants to grant the SEPA mandate and inputs its bank account informations or if the client doesn’t want to grant it.',
            },
          },
          title: 'Digital Signature',
          warningCard: {
            description:
              'Digital signature is currently in the Alpha phase and we are testing it intensively. Please be aware that some functions may not yet work as expected and could change over the course of the development phase.',
            message: 'NOTE',
          },
        },
        Email: {
          dkimAlert: {
            description:
              'To send emails using your own domain, you need to add a DKIM entry to your DNS settings. Please contact your domain administrator to add the entry. You can also add the entry yourself, if you have access to your DNS settings. If you want to add the entry yourself, you can find more information here:',
            message: 'Note',
          },
          dkimHelperWidget: {
            howUseButton: 'Tutorial: Authenticate domain with DKIM',
            modalInfo: {
              helpText: 'Here you will find additional instructions on DKIM: ',
              imageUrl: '',
              ionosGuide: {
                link: 'https://www.ionos.de/digitalguide/e-mail/e-mail-sicherheit/dkim-domainkeys/',
                title: 'IONOS: setup DKIM for your domain (German)',
              },
              stratoGuide: {
                link: 'https://www.strato.de/faq/mail/wie-kann-ich-fuer-meine-domain-die-dkim-einstellungen-aendern/',
                title: 'Strato: how to change DKIM settings for your domain (German)',
              },
              title: 'Set up DKIM',
              videoCaption: 'Learn how to add DKIM entries to your DNS settings',
              videoUrl: 'https://vimeo.com/883885985',
            },
          },
          error: {
            couldNotGetDomainConfig:
              'An error occurred while processing your domain configuration. Please try again later.',
            domainAuthenticationRequestFailed:
              'An error occurred during the verification of your domain. Please try again later.',
            invalidDomainName: 'Your domain seems to be invalid. Please check your input and try again.',
          },
          inputs: {
            activeUserToBCC: {
              label: 'Send BCC copy to active user',
              tooltip:
                'Should the current user get a copy of a sending email on its email address? We recommend to activate this function.',
            },
            attachmentsSizeLimit: {
              label: 'Attachments size limit (MB)',
            },
            bcc: {
              label: 'Send all emails as a BCC copy to this adress',
            },
            customDomain: {
              introtext:
                'Your domain gets prefilled from your sender address. Currently only the domain used for the sender address can be authentificated.',
              label: 'Your domain',
              notAuthText:
                'Currently the domain isn`t authentificated. Please do the configuration from below and click on `Check configuration`.',
              placeholder: 'Domain',
              subHeading: 'Basic DKIM configuration',
              tooltip: 'Your domain is extracted from the sender`s email address.',
              useCustomDomain: 'Send with custom domain (DKIM & SPF)',
              validationError: 'Please enter a valid domain',
            },
            dkimStatusCheck: {
              checkStatus: 'Check configuration',
              heading: 'Final check',
              headingAppendix: '(Type: TXT)',
              status: {
                error: 'Status: Error while checking',
                last_checked: ', last checked on {{date}}',
                loading: 'Status: checking...',
                success_connected: 'Status: connected',
                success_notConnected: 'Status: not connected',
              },
              tooltip: {
                connected: 'Authenticated',
                notConnected: 'Not authenticated',
              },
            },
            dnsSettings: {
              dkimRecord: {
                heading: 'DKIM record',
                label: 'DKIM record value',
                prefixLabel: 'DKIM record hostname',
                type: 'TXT',
                typeLabel: 'DKIM record type',
              },
              dmarcRecord: {
                description:
                  'To improve the deliverability of your emails, we recommend you to set a dmarc policy in your dns entries. If you don`t have already a dmarc policy, we recommend so following entry (Recommendation by our email service provider): ',
                heading: 'Optional: DMARC record',
                hostname: '_dmarc',
                hostnameLabel: 'DMARC record hostname',
                label: 'DMARC record value',
                message: 'Information to improve deliverability',
                type: 'TXT',
                typeLabel: 'DMARC record type',
                value: 'v=DMARC1;p=none;rua=mailto:rua@dmarc.brevo.com',
              },
              downloadButton: 'Download configuration',
              heading: 'DNS entries for domain administrator',
              introAlertText:
                'Here you have the configuration details to configure dkim and spf for your domain. The entries listed here have to be set in the dns server for your domain. When you are the responsible person for the domain or the responible person for the domain is a team member, then the hostnames and values for the entries can be copied here by click on the fields. When you commissioned some one external to manage your domain, then you have to send the config data to the commissioned person. An entry consists of a type, an hostname and a value.',
              spfRecord: {
                heading: 'SPF record',
                hostname: '@',
                label: 'SPF record value',
                prefixLabel: 'SPF record hostname',
                type: 'TXT',
                typeLabel: 'SPF record type',
              },
            },
            from: {
              invalidEmailFormat:
                'Please enter a valid email address, in the format: Max Mustermann <max.m@domain.com>',
              label: 'From',
              tooltip: 'This email address will be used as the sender address.',
            },
            mailServiceProvider: {
              default: 'Send via Kanzlei Pilot (Not whitelabelled)',
              label: 'Email service provider',
              smtp: 'Send via own Email server',
            },
            options: {
              label: 'Connection options',
              requireTLS: {
                tooltip:
                  'If this is checked and Use TLS is unchecked then backend tries to use STARTTLS even if the server does not advertise support for it. If the connection can not be encrypted then message is not sent',
              },
              useSSLv3: {
                tooltip:
                  'Require TLS even if server does not advertise support for it. In most cases use SSL v3 if TLS not work and you are connecting to port 465. For port 587 or 25 you can try to leave it unchecked',
              },
              useTLS: {
                tooltip:
                  'In most cases use TLS if SSL v3 not work and you are connecting to port 465. For port 587 or 25 you can try to leave it unchecked',
              },
            },
            requireTLS: 'Require TLS',
            smtpPassword: 'SMTP password',
            smtpPort: 'SMTP Port',
            smtpServer: 'SMTP Server',
            smtpUserName: 'SMTP Username',
            useSSLv3: 'Use SSL v3',
            useTLS: 'Use TLS',
          },
          mailServiceIsNotSmtp: 'You must first select and save the SMTP settings',
          otherSettingTitle: 'Other Email Settings',
          saveAndTestEmail: 'Send Test-Email',
          saveAndTestEmailAlert: {
            description: 'SMTP rocks.',
            message: 'Hint',
          },
          saveAndTestEmailModal: {
            cancelText: '$t(common.cancel)',
            errorMessage: 'Email could not be sent',
            okText: '$t(common.ok)',
            successMessage: 'Email sent successfully',
            title: 'We’ll send a test email with the last saved settings to your email {{email}}',
          },
          setBestSMTPSettingsTitle:
            'You just changed the SMTP-settings. Do you want us to find and set the most secure port + connection option for you? (Yes / NO)',
          settingTitle: 'SMTP Settings',
          signatureTitle: 'Email signature',
          smtpSetting: {
            button: 'Find & set best smtp setting',
            found:
              'We have found a SMTP setting and updated the settings. Please press the "Send Test Mail"-button to send a test-message to your account.',
            loading:
              'We are talking to the SMTP host right now. Please wait and leave this page open. It can take a few minutes...',
            notFound:
              'We have NOT found a SMTP setting. Something is wrong. Please look if you have a typo in the username, password or host. You can click the "show details"-button below to see what combinations we have tried.',
            showDetails: 'Show details',
            validationError:
              'Please set host, username, password and from-adress in form below first. Then press this button again.',
          },
          title: 'Email settings',
        },
        ImportCompanies: {
          alert: {
            step1: {
              description: 'Download a excel template',
              linkDescription: '/StammdatenImportMusterdatei.xlsx',
              message: 'In order to mass-import companies please upload a csv',
            },
            step3: {
              description: '',
              message: 'This is the default value we set it NO company type is set.',
            },
          },
          alertLimitCompany:
            "You can't create {{numberCompaniesInsert}} companies, only {{nrOfCompaniesLimit}} are available to create",
          completePage: {
            numberInsert: 'Insert {{count}} new company',
            numberInsert_plural: 'Insert {{count}} new companies',
            numberUpdate: 'Update {{count}} company',
            numberUpdate_plural: 'Update {{count}} companies',
            tip: 'Tip: You can see changes to a company in company history',
            title: 'Import successful',
          },
          errors: {
            duplicateIdentifier:
              'This row contains an non-unique identifier. The same identifier is used in at least 1 other row. Please clean up your csv-source to import this row.',
            duplicateName:
              'This row contains an non-unique company name. The same company name is used in at least 1 other row. Please clean up your csv-source to import this row.',
          },
          fields: {
            city: 'City',
            cityCode: 'City code',
            commercialObject: 'Commercial object',
            country: 'Country',
            defaultCompanyType: 'Default company type',
            houseNumber: 'House number',
            identifier: 'Identifier',
            importAcademicTitle: 'Import Academic Title',
            importCompanyType: 'Import company type',
            importEmail: 'Import Email',
            importSalutation: 'Import salutation',
            name: 'Name',
            street: 'Street',
          },
          navigationButtons: {
            import: '$t(common.navigationButtons.import)',
            next: '$t(common.navigationButtons.next)',
            previous: '$t(common.navigationButtons.previous)',
          },
          numberImport: '{{numberCompaniesInsert}} companies to be imported',
          numberUpdate: '{{numberCompaniesUpdate}} companies to be updated',
          status: {
            invalid: 'Invalid ',
            valid: 'Valid',
          },
          step1: 'Upload csv file',
          step2: 'Map Columns',
          step3: 'Set default Values',
          step4: 'Preview',
          table: {
            after: 'After',
            before: 'Before',
            city: '$t(admin.Setting.ImportCompanies.fields.city)',
            cityCode: '$t(admin.Setting.ImportCompanies.fields.cityCode)',
            columnSource: {
              placeholder: '(please choose)',
              title: 'Column Source',
            },
            columnTarget: 'Column Target',
            companyIdentifier: '$t(admin.Setting.ImportCompanies.fields.companyIdentifier)',
            country: '$t(admin.Setting.ImportCompanies.fields.country)',
            importType: {
              insert: 'insert',
              label: 'Import type',
              update: 'update',
            },
            name: '$t(admin.Setting.ImportCompanies.fields.name)',
            status: 'Status',
            street: '$t(admin.Setting.ImportCompanies.fields.street)',
          },
          title: 'Import Companies',
          uploadButton: 'Upload Csv',
        },
        LicenseOverview: {
          columns: {
            licenseStarted: 'License started',
            nrOfCompaniesCreated: 'Number of companies in system',
            nrOfCompaniesLimit: 'Number of companies allowed by license',
            nrOfUsersCreated: 'Number of users in system',
            nrOfUsersLimit: 'Number of users allowed by license',
          },
          restartOnboarding: 'Restart onboarding',
          title: 'License Overview',
        },
        MonthlyPaymentPreferences: {
          documentsSettings: {
            cardLabel: 'In the documents',
            infoAlertText:
              'Please note that you can configure PDF-related texts in the block named "Your investment overview”',
          },
          generalSettings: {
            cardLabel: 'General Preferences',
            monthlyPaymentMode: {
              disabledOption: 'Disabled',
              disabledOptionTooltip: 'The Monthly Payment feature is inactive.',
              label: 'How do you want to use "Monthly Payment"?',
              optionalOption: 'Optional for company',
              optionalOptionTooltip:
                'Company can decide if they want to use monthly payment. User can switch in on or off in Shopping cart while talking to company - or leave it unselected. Company can then select in contract and/or self-service digital signature app if they want to use monthly payment.',
              requiredOption: 'Mandatory for company',
              requiredOptionTooltip: 'Company is required to pay monthly payment.',
            },
            monthlyPaymentTitle: {
              label: 'How do you want to name your ”Monthly Payment”?',
              tooltip:
                'This is the name which will be displayed in the total-calculation view of the shopping-card (left column).',
            },
            roundingOptions: {
              label: 'Do you want to round up the "Monthly Payment"?',
              tooltip:
                'You can decide to round the monthly payment here - it will affect the final monthly payment in both modes (in standard-mode and "fixed monthly advance" in "fixed monthly"-mode)',
            },
          },
          helperWidget: {
            howUseButton: 'Tutorial: How to use Monthly Payments',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'Tutorial for setting up Monthly Payments',
              videoCaption: 'Get more informations about Monthly Payments',
              videoUrl: 'https://vimeo.com/862502268',
            },
          },
          shoppingCartSettings: {
            cardLabel: 'In the shopping-cart screen',
            defaultOption: {
              label: 'What default do you want to set for a fresh shopping-cart?',
              optionDecideLater: 'Decide Later',
              optionNo: 'No',
              optionYes: 'Yes',
              tooltip:
                'Here you can preselect an option for the client if Monthly Payment is optional for the company. If the client wants an other option, you can change the option in the shopping-cart creation process.',
            },
            helpText: {
              label:
                'What help-text do you want to display to company explaining the specific rules of monthly payment – when asking for their decision?',
              tooltip:
                'This text will appear in the shopping-cart next to the question for company to find out more details about the monthly payment option.',
            },
            question: {
              label:
                'What question do you want to display to company to ask for their decision, if decision is optional?',
              tooltip:
                'The question is going to appear in shopping-cart and signature-process and ask company about their decision. It only appears if monthly payment is an optional choice for company.',
            },
          },
          signatureSettings: {
            cardLabel: 'During signature process',
            signaturePageIntroductionText: {
              label: 'Introduction text',
              tooltip:
                'A signee can see this text when he is making a selection if he wants to use Monthly Payment or not.',
            },
            signaturePageOptionNo: {
              label: 'Option: No, pay monthly and yearly items separate',
              tooltip: 'Here you can set the text for the No-option.',
            },
            signaturePageOptionYes: {
              label: 'Option: Yes, pay advance',
              tooltip: 'Here you can set the text for the Yes-option.',
            },
            signaturePageTitle: {
              label: 'Title',
              tooltip:
                'A signee can see this title when he is signing documents and have to make a decision about using Monthly Payment or not as heading for the decision page.',
            },
          },
          title: 'Monthly Payment Settings',
        },
        ShoppingCartPreferences: {
          HelperWidget: {
            howUseButton: '$t(common.howUseButton)',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'How to use shopping cart preferences',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/163721649',
            },
          },
          card: {
            customPrice: 'Custom prices',
            viewOptions: 'Set view options by default',
          },
          fields: {
            allowCustomPrices: {
              label: 'Allow users to set custom prices',
              tooltip: 'If enabled, users can set custom prices in shopping-cart screen',
            },
            feeType: {
              tooltip:
                'Calculation mode in which KanzleiPilot calculates the prices. In standard mode everything is the same: one-time, monthly and annual services are displayed transparently. In the entrepreneur fee mode, monthly and annual items are combined at a monthly price and their individual prices are hidden with the note "The service is included in the entrepreneur fee". One-off services are also shown transparently in the company fee.',
            },
            showCalculated: {
              label: 'Show orginal price in documents',
              tooltip:
                'Here you can set the default for showing the orginal price, if you configured an individual price. If this option is enabled, also the orginal prices are shown in documents in case that you configured an individual price. You can configure that behaviour in each cart and each individual price individually.',
            },
            showCategoryTotal: {
              label: 'Show Category total price preview',
              tooltip:
                'If switched on, the shopping cart will show "Category total price preview" for all categories by default.',
            },
            showDiscount: {
              label: 'Show Discounts',
              tooltip:
                'Should discounts be displayed when opening the menu card? We recommend you: Switch the display of discounts in the standard OFF and ONLY show it if a client asks for discounts and you have a suitable discount for him in the program. Tip: if a client “complains” about the price, ask them which additional services they can do without - instead of offering them a discount.',
            },
            showPrice: {
              label: 'Show Prices (of category & items, including discounted prices)',
              tooltip:
                'Should prices be displayed when opening the menu card? We recommend you: Switch the price display OFF in the standard. In this way, when talking to your client, you can first take care of the current situation and record their wishes with them. If you are then asked about the price, you can display the prices on the menu card at the push of a button (please watch the explanatory video in the interactive menu card).',
            },
          },
          pricingSetting: {
            roundPrice: {
              defaultOption: 'Use global standard',
              title: 'Round single items',
            },
            showDigits: {
              title: 'Show digits',
            },
            title: 'Pricing Setting',
          },
          reset: 'Reset default settings',
          resetConfirmModalMessage:
            'Danger: Are you sure you want to reset Settings to KanzleiPilots defaults? You will loose your customized settings! Continue?',
          selectDefaultEmailTemplate: 'Select default email template',
          title: 'Shopping Cart preferences',
        },
        TenantCompany: {
          HelperWidget: {
            howUseButton: '$t(common.howUseButton)',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'How to use your company data',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/163721649',
            },
          },
          inputs: {
            city: {
              title: 'City',
            },
            cityCode: {
              title: 'City code',
            },
            country: {
              title: 'Country',
            },
            creditorIdentNr: {
              title: 'Creditor identification number',
            },
            houseNumber: {
              title: 'House number',
            },
            name: {
              title: 'Name',
            },
            officeEmail: {
              title: 'Office email',
            },
            phoneNumber: {
              title: 'Phone number',
            },
            street: {
              title: 'Street',
            },
            website: {
              title: 'Website',
            },
          },
          title: 'Your Company Data',
        },
      },
      addCategoryModal: {
        addModalTitle: 'Create a new category',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Category with the same name already exists',
        nameFieldDescription: 'Category name',
        nameFieldLabel: 'Name',
        ok: 'Ok',
      },
      addDiscountButton: 'Add Discount',
      addDocumentTemplateModal: {
        addModalTitle: 'Create a new document template',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Document template with the same name already exists',
        nameFieldDescription: 'Document name',
        nameFieldLabel: 'Name',
        ok: 'Ok',
      },
      addInputButton: 'Add input field',
      addItemButton: 'Add Item',
      addStaticItemButton: 'Add static item',
      categoryNameInput: {
        label: 'Name',
      },
      categoryTexts: {
        guidanceText: {
          label: '$t(common.fields.guidanceText.label)',
        },
        guidanceVideoId: {
          label: '$t(common.fields.guidanceVideoId.label)',
        },
        internalNote: {
          label: 'Internal note for kanzleipilot team',
        },
        introForDocument: {
          label: '$t(common.fields.introForDocument.label)',
          tooltip:
            "Is displayed in the block type “Service Details” below the area name and appears in the offer, for example. Give a bird's eye view of the area, describe the benefits for the customer and position yourself as an expert.",
        },
        name: {
          label: '$t(common.fields.name.label)',
          tooltip: 'Name of the area. This is how it appears in the interactive menu map and in the documents.',
        },
        subject: {
          tooltip:
            'Brief description that appears in the interactive menu map below the area name. Useful to emphasize your expert status and the benefit for the client.',
        },
      },
      deleteCategory: 'Delete Category',
      deleteCategoryConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the category?',
      },
      deleteDiscountConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the discount?',
      },
      deleteInputConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the input field?',
      },
      deleteItemConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        openModalButtonTitle: 'Remove item',
        title: 'Are you sure you want to remove the item?',
      },
      deleteStaticItemConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the static item?',
      },
      deleteStatusConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Sure to delete the status? Existing shopping carts will be set to default status',
      },
      deleteUserConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the user?',
      },
      deleteVariableConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the variable?',
      },
      discountFieldModal: {
        addModalTitle: 'Create a new Discount',
        duplicatedErrorMessage: 'Discount with the same name already exists',
        editModalTitle: 'Edit Discount',
        nameFieldDescription: 'Discount name',
        nameFieldLabel: 'Name',
        typeFieldAbsolute: '$t(common.Discount.absolute)',
        typeFieldLabel: 'Type',
        typeFieldPercent: '$t(common.Discount.percent)',
        valueFieldDescription: 'Discount value',
        valueFieldLabel: 'Value',
      },
      discountTableColumns: {
        actions: '',
        name: 'Name',
        type: 'Type',
        value: 'Value',
      },
      discountUsedInShoppingCartConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        titleDelete: 'Discount is used in existing shopping carts. Remove?',
        titleUpdate: 'Discount is used in existing shopping carts. Update?',
      },
      discountsTitle: 'Discounts',
      duplicateCategoryConfirmation: {
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Category names are unique. Please enter the new name',
        ok: '$t(common.ok)',
        openModalButtonTitle: 'Duplicate category',
        title: 'Please enter new category name',
      },
      duplicateItemConfirmation: {
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Item names are unique. Please enter the new name',
        ok: '$t(common.ok)',
        openModalButtonTitle: 'Duplicate item',
        title: 'Please enter new item name',
      },
      guidanceAlert: {
        category: 'Infos about this category',
        closeForever: {
          buttonTitle: 'Close forever',
          title: 'Do you want to hide forever {{name}} guide?',
        },
        item: 'Infos about this item',
      },
      importCategoryPresetsModal: {
        errorMessage: 'Categories with such names {{duplicatedCategories}} have already been added to the list',
        import: 'import',
        title: 'Import Category-Presets from Common Library',
      },
      inputFieldModal: {
        addModalTitle: 'Create a new Input Field',
        cancel: '$t(common.cancel)',
        comboOptions: {
          addOption: 'add Option',
          boxLabel: 'Combobox options',
          modal: {
            addTitle: 'Create new option',
            cancel: '$t(common.cancel)',
            duplicatedErrorMessage: 'Input field option with the same name already exists',
            editTitle: 'Edit option',
            nameFieldDescription: 'Input Field option name',
            nameFieldLabel: 'Name',
            ok: 'Ok',
            valueFieldDescription: 'Input Field option value',
            valueFieldLabel: 'Value',
          },
          table: {
            actionsColumnLabel: '',
            deleteOptionConfirmation: {
              cancel: '$t(common.cancel)',
              ok: '$t(common.ok)',
              title: 'Are you sure you want to remove the option?',
            },
            nameColumnLabel: 'Name',
            valueColumnLabel: 'Value',
          },
        },
        duplicatedErrorMessage: 'Input Field with the same name already exists',
        editModalTitle: 'Edit Input Field',
        fields: {
          allowedRange: {
            label: 'Allowed value range',
          },
          allowedRangeMaxValue: {
            label: 'Max-value',
            maxIsLessThenDefaultValue: 'Please correct the "max value" as it\'s less than the "default value"',
            maxIsLessThenMin: 'Please correct the "max value" as it\'s less than the "min value"',
          },
          allowedRangeMinValue: {
            label: 'Min-value',
            minIsMoreThenDefaultValue: 'Please correct the "min value" as it\'s greater than the "default value"',
            minIsMoreThenMax: 'Please correct the "min value" as it\'s greater than the "max value"',
          },
          defaultInput: {
            label: '$t(common.fields.defaultValue.label)',
          },
          defaultType: {
            input: 'enter number',
            label: 'How do you want to set the default-value?',
            variable: 'take from variable',
          },
          defaultValue: {
            defaultValueIsOutOfRange: 'Please correct the "default value" as it\'s out of the allowed range',
          },
          defaultVariable: {
            label: 'Take default-value from variable:',
          },
          name: {
            description: 'Input Field name',
            label: '$t(common.fields.name.label)',
          },
          optionPDFNameTitle: {
            label: 'Title for option',
            placeholder: 'Title for option',
          },
          optionPDFValueTitle: {
            label: 'Title for value',
            placeholder: 'Title for value',
          },
        },
        ok: 'Ok',
        pdfConfigurationLabel: 'PDF configuration',
        typeFieldLabel: 'Type',
        unitNameDescription: 'Unit Field name',
        unitNameLabel: 'Unit name',
        variableAlert:
          'Variable that you have chosen is equal to {{value}}. Please consider that when filling allowed range',
      },
      inputFieldTableColumns: {
        actions: '',
        name: 'Name',
        type: 'Type',
      },
      inputsTitle: 'Input Fields',
      itemModal: {
        ConditionalVisibilityHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use item conditional visibility',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        InputFieldsHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use item input fields',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        PriceHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use item price',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        ScalesHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use item pricing scales',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        TextsHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use item texts',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        addTitle: 'New item',
        cancel: '$t(common.cancel)',
        copied: 'Copied link to clipboard!',
        copyCLLink: 'Copy link of common library service to clipboard',
        duplicatedErrorMessage: 'Item with the same name already exists',
        editSubTitleForItem: 'Edit item {{itemName}}',
        editTitle: 'Edit item',
        guideAlert: {
          guidanceVideoButton: 'Display guidance video',
          guidanceVideoModalTitle: 'Guidance video',
        },
        inputs: {
          benefits: {
            label: '$t(common.fields.benefits.label)',
            tooltip:
              'Benefit for the customer who appears in the offer. Focus here on the added value that the customer has through their investment in the service.',
          },
          calculationMode: {
            label: '$t(common.fields.calculationMode.label)',
            tooltip:
              'Do you want to give a price/value for the item OR communicate that the price is on "$t(common.Item.calculationMode.onActualCost)"? Make your choice here.',
          },
          descriptionForContract: {
            label: '$t(common.fields.descriptionForContract.label)',
            tooltip:
              'In the scope of the order, you clearly describe the scope of your service. Clearly demarcate your performance here. The standard text appears in the remuneration agreement and in the tax advisory contract.',
          },
          guidanceText: {
            label: '$t(common.fields.guidanceText.label)',
            placeholder: '$t(common.fields.guidanceText.label)',
          },
          guidanceVideoId: {
            label: '$t(common.fields.guidanceVideoId.label)',
            placeholder: '$t(common.fields.guidanceVideoId.label)',
          },
          highlightBindingness: {
            label: '$t(common.fields.highlightBindingness.label)',
            tooltip:
              'Do you want to put special focus on the bindingness of the given price? If yes, then you can make your choice here and the bindingness will be highlighted.',
          },
          infoText: {
            label: '$t(common.fields.infoText.label)',
            tooltip:
              'This text appears in the interactive menu map when you press the info button. Emphasize the benefits for the customer.',
          },
          minPrice: {
            label: '$t(common.fields.minPrice.label)',
            tooltip:
              'Minimum price that applies if the price calculated in the price formula is too low. Enter 0 to set NO reserve price.',
          },
          name: {
            label: 'Name',
            placeholder: '',
            tooltip: 'Name of the service. This is how it appears in the interactive menu map and in the documents.',
          },
          notesForFeeAgreement: {
            label: '$t(common.fields.notesForFeeAgreement.label)',
            tooltip:
              'Use this text to add notes for fee-agreement. It is available in service-details and bookmarked-items block.',
          },
          notesToEmployee: {
            label: '$t(common.fields.notesToEmployee.label)',
            tooltip:
              'Space for internal information for your law firm team. The text appears in the reading view for an existing menu card. Useful, e.g. for a reference to related order numbers, etc.',
          },
          paymentInterval: {
            label: '$t(common.fields.paymentInterval.label)',
            monthlyLabel: '$t(common.Item.paymentIntervalValue.monthly)',
            oneOffLabel: '$t(common.Item.paymentIntervalValue.oneOff)',
            tooltip:
              'Payment interval of the service. In the entrepreneur fee mode, annual and monthly services for the client are summarized in the menu card in a monthly price.',
            yearlyLabel: '$t(common.Item.paymentIntervalValue.yearly)',
          },
          pleaseNote: {
            label: '$t(common.fields.pleaseNote.label)',
            tooltip:
              'Set up a set of rules here: If the price applies, what does the client have to deliver? What information do you need from him? Which service is not included? In the standard system, the text appears in the offer, the remuneration agreement and in the tax advisory contract.',
          },
          pricingFormula: {
            editConstants: 'Edit constants',
            errors: {
              invalid: '{{name}} is invalid',
            },
            insertVariableMenu: {
              disabledMenuTooltip: {
                categoryMonthlyAdvance:
                  'Category does NOT contain an active item with paymentInterval monthly or yearly.',
                inactiveItem: 'Item is set inactive.',
                onActualCostItem: 'Placeholder is disabled because the item is on actual cost mode.',
              },
              subMenu: {
                category: 'Inputs from category',
                categoryMonthlyAdvance: 'Categories total sum',
                categoryTotal: 'Total value from category',
                function: 'Functions',
                item: 'Inputs from position',
                itemTotal: 'Total value from item',
                scaleFunctionParameters: 'Scale function parameters',
                variable: 'Variables',
              },
              title: 'Insert Placeholder',
            },
            label: '$t(common.fields.pricingFormula.label)',
            tooltip:
              'The price formula based on which the price is calculated. Tip: Take a look at the explanatory videos to get to know this important function in detail.',
          },
          roundPrice: {
            label: 'Round single items',
            tooltip:
              'Set this option to round the value calculated by pricing formula. Tip: You can set global settings at settings > shopping-cart > shopping-cart preferences',
          },
          scales: {
            addModalLabel: 'Create a new Scale',
            atLeastTwoError: 'There must be at least two scales',
            cancel: '$t(common.cancel)',
            deleteModal: {
              cancel: '$t(common.cancel)',
              ok: '$t(common.ok)',
              title: 'Are you sure you want to remove the Scale?',
            },
            duplicatedError: 'Each scale must be unique',
            duplicatedErrorMessage: 'Scale with the same name already exists',
            editModalLabel: 'Edit Scale',
            emptyScaleList:
              'There are no scales yet. Create one by clicking the "$t(admin.itemModal.inputs.scales.addModalLabel)" button',
            emptyTable:
              'There are no scales yet. Create one by clicking the "$t(admin.itemModal.inputs.scales.addModalLabel)" button',
            inputs: {
              formula: {
                error: 'Formula is invalid',
                label: 'scale formula',
              },
              graduatedScaleMode: {
                graduated: 'Graduated',
                label: 'Scaled pricing mode',
                standard: 'Standard',
                tooltip:
                  'You can choose between 2 modes: 1) in standard mode the scale function simply returns the value for the given tier. 2) in “graduated mode“ the scale function automatically sums up the values with the tier-prices of the previous tiers, p.e. at at setup of 1-3 = 2 & 4 - 6 = 1,5 it will return 7,50 for the input value of 4. Calculation = 3*2 + 1 * 1,5',
              },
              pricingFormulaTitle: {
                label: 'Pricing formula title',
                placeholder: 'Pricing formula title (for PDF display)',
                tooltip:
                  'In the price formula you can use the scale function to implement graduated prices. Here you can configure the scales and values, which you can then call up in the price formula with the scale function.',
              },
              pricingFormulaUnit: {
                label: 'Pricing formular unit',
                placeholder: 'Unit',
                tooltip:
                  'Here you can configure an unit for the pricing column for the pricing table. If this field is empty, then the pricing column has no unit. Default unit is € (Euro).',
              },
              scaleTitle: {
                label: 'Scale title',
                placeholder: 'Scale title (for PDF display)',
                tooltip:
                  'In the price formula you can use the scale function to implement graduated prices. Here you can configure the scales and values, which you can then call up in the price formula with the scale function.',
              },
              scaleUnit: {
                label: 'Scale unit',
                placeholder: 'Unit',
                tooltip:
                  'Here you can configure an unit for the scale column for the pricing table. If this field is empty, then the scale column has no unit.',
              },
              unitName: {
                label: 'Scale unit name',
                placeholder: 'Scale unit name',
              },
              value: {
                label: 'scale value',
              },
            },
            label: 'Configure "Pricing Scales" function?',
            ok: 'Ok',
            pdfConfigurationLabel: 'PDF configuration',
            pdfConfigurationUnitsLabel: 'PDF units configuration',
            table: {
              columns: {
                pricingFormula: 'Pricing Formula for Scale',
                value: 'Scale',
              },
            },
            tooltip:
              'In the price formula, you can use the scale function to implement graduated prices. Here you can configure the scales and values, which you can then call up in the price formula with the scale function.',
            wrapperLabel: '"Pricing Scales" function',
          },
          scalesList: {
            label: 'Scales list',
          },
          subTitle: {
            label: 'Subtitle',
            placeholder: '',
            tooltip:
              'Brief description of the service that appears in the interactive menu map below the area name. Useful to direct the focus on a certain service, to emphasize the benefit for the client and to position yourself as an expert.',
          },
        },
        internalNoteWarning: 'Internal note for kanzleipilot team',
        ok: 'Ok',
        pricingWarning:
          'Warning: You have a negative pricingFormula Formula and a minPrice of "{{value}}" configured. The minPrice will affect the calculated price. Please remove the minPrice if you want the calculated price to be negative.',
        tabs: {
          conditionalVisibility: 'Conditional Visibility',
          inputs: 'Input Fields',
          price: 'Price',
          texts: 'Texts',
        },
      },
      itemTable: {
        columns: {
          actions: '',
          active: 'active',
          name: 'Name',
          paymentInterval: 'Payment Interval',
          pricingFormula: 'Pricing Formula',
        },
        title: 'Items',
      },
      pricingFormulaInput: {
        functions: {
          StBVV_TabelleA: 'StBVV_TabelleA(nr or formula) - p.e. "StBVV_TabelleA(140000)" will be 1773',
          StBVV_TabelleB: 'StBVV_TabelleB(nr or formula) - p.e. "StBVV_TabelleB(5000)" will be 81',
          StBVV_TabelleC: 'StBVV_TabelleC(nr or formula) - p.e. "StBVV_TabelleC(45000)" will be 122',
          StBVV_TabelleD_a: 'StBVV_TabelleD_a(nr or formula) - p.e. "StBVV_TabelleD_a(951)" will be 1738',
          StBVV_TabelleD_b: 'StBVV_TabelleD_b(nr or formula) - p.e. "StBVV_TabelleD_b(42500)" will be 380',
          round: 'round(nr or formula) - p.e. "round(7.8)" will round up to 8',
          scale:
            'scale(nr or formula) - p.e. "scale(342, 23)" will pass parameters 342 and 23 as inputValue1 and inputValue2 into Pricing Scales function defined in this page below',
        },
        functionsListText: 'The following functions are available:',
        howUseButton: '$t(common.howUseButton)',
        inputsListText: 'The following Input Fields are available:',
        modalInfo: {
          helpText: '',
          title: 'How to use pricing formula input',
          videoCaption: '',
          videoUrl: 'https://vimeo.com/163721649',
        },
        syntaxInstructions:
          'Operators: +, -, /, *, %, ^\nType a "@" to insert an input field\nType a "#" to insert a function',
      },
      resetTextStylesConfirmation: {
        cancel: '$t(common.cancel)',
        title:
          'Are you sure that you want to reset text-styles? All styling information (bullet-points, bold, italic, underline, etc.) will be lost. Continue?',
        yes: '$t(common.yes)',
      },
      staticItemsFieldModal: {
        addModalTitle: 'Create a new static item',
        duplicatedErrorMessage: 'Static item with the same name already exists',
        editModalTitle: 'Edit Static item',
        errors: {
          isEmpty: '{{label}} is a required field',
        },
        nameFieldDescription: 'Static item name',
        nameFieldLabel: 'Name',
        valueFieldDescription: 'Static item value',
        valueFieldLabel: 'Value',
      },
      staticItemsTableColumns: {
        actions: '',
        name: 'Name',
        value: 'Value',
      },
      staticItemsTitle: 'Static Items',
      textsTabSubmitButton: 'Save changes',
      textsTabTitle: 'Texts',
      textsTitle: 'Texts',
    },
    availableTime: {
      daysLeft_one: '({{daysLeft}} day left)',
      daysLeft_other: '({{daysLeft}} days left)',
      hoursLeft_one: '({{hoursLeft}} hour left)',
      hoursLeft_other: '({{hoursLeft}} hours left)',
      minutesLeft_one: '({{minutesLeft}} minute left)',
      minutesLeft_other: '({{minutesLeft}} minutes left)',
      noTimeLeft: '(No time left)',
    },
    common: {
      ConditionalVisibility: {
        balanceOfAccountsGmbH: 'Balance of accounts - GmbH',
        balanceOfAccountsGmbHAndCoKg: 'Balance of accounts - GmbH & Co. Kg',
        balanceOfAccountsGmbHAndCoKgSelfBooker: 'Balance of accounts - GmbH & Co. Kg (self-booker)',
        balanceOfAccountsGmbHSelfBooker: 'Balance of accounts - GmbH (self-booker)',
        balanceOfAccountsOther: 'Balance of accounts - other',
        balanceOfAccountsOtherSelfBooker: 'Balance of accounts - other (self-booker)',
        balanceOfAccountsSingle: 'Balance of accounts - single',
        balanceOfAccountsSingleSelfBooker: 'Balance of accounts - single (self-booker)',
        balanceOfAccountsUG: 'Balance of accounts - UG (with limited liability)',
        balanceOfAccountsUGSelfBooker: 'Balance of accounts - UG (with limited liability) (self-booker)',
        balanceOfUnion: 'Balance of accounts - union',
        balanceOfUnionSelfBooker: 'Balance of accounts - union (self-booker)',
        cashBasisAccountingCapital: 'Cash basis accounting - capital',
        cashBasisAccountingCapitalSelfBooker: 'Cash basis accounting - capital (self-booker)',
        cashBasisAccountingSingle: 'Cash basis accounting - single',
        cashBasisAccountingSingleSelfBooker: 'Cash basis accounting - single (self-booker)',
        cashBasisAccountingUnion: 'Cash basis accounting - union',
        cashBasisAccountingUnionSelfBooker: 'Cash basis accounting - union (self-booker)',
        checkAll: 'Check all',
        other: 'Other',
        otherSelfBooker: 'Other (self-booker)',
        privatePerson: 'Private person',
        uncheckAll: 'Uncheck all',
      },
      Discount: {
        absolute: 'absolute',
        percent: 'percent',
      },
      Error: {
        required: '{{field}} is required',
      },
      InputField: {
        combo: 'combobox',
        copiedToClipboard: 'copied to clipboard',
        input: 'input',
        internal: 'internal',
        name: 'Input field',
      },
      Item: {
        calculationMode: {
          onActualCost: 'On actual costs',
          value: 'Value',
        },
        highlightBindingness: {
          dynamic: 'Dynamic',
          dynamicDativ: 'dynamic',
          dynamicDativ_plural: 'dynamic',
          estimated: 'Estimated',
          estimatedDativ: 'estimated',
          estimatedDativ_plural: 'estimated',
          fixPriced: 'Fix priced',
          fixPricedDativ: 'fix priced',
          fixPricedDativ_plural: 'fix priced',
          noHighlight: "Don't highlight",
        },
        paymentIntervalValue: {
          fixedMonthly: 'fixed monthly price',
          monthly: 'monthly',
          oneOff: 'one-off',
          yearly: 'yearly',
        },
        recursive: "This item's formula includes items that reference this item (recursive reference)",
        scaleError: 'The formula references scale function which needs to be defined below',
        switchToOnActualCost:
          'Item Calculation can not be switched because the item is currently on "value"-item-calculation-mode and is used in pricing formula of item {{items}}. It can NOT be switched to on actual cost.',
      },
      MoreInfoWidget: {
        book: {
          calendly: 'Book 15 minute onboarding session',
          calendlyUrl: '$t(common.urls.calendlyUrl)',
          title: 'Do you still have questions? The please book a session with us',
        },
      },
      RoundPrices: {
        noRound: 'Do NOT round',
        roundUpTo1: 'Round up to 1 €',
        roundUpTo10: 'Round up to 10 €',
        roundUpTo25: 'Round up to 25 € (0, 25, 50 or 75)',
      },
      Variables: {
        types: {
          float: 'Float',
          percent: 'Percent',
          pricePerHour: 'Price per hour',
          text: 'Text',
        },
      },
      activateYourAccount: 'Activate your account',
      alertInAcceptInvitationForm: 'Please set your password to activate your KanzleiPilot-account',
      and: 'and',
      buttons: {
        resendInvitation: {
          tooltip: 'Resend invitation E-Mail',
        },
        resendVerificationEmail: 'Resend verification email',
      },
      cancel: 'Cancel',
      closeToastText: 'Close this message',
      columns: {
        actions: 'Actions',
        body: 'Body',
        category: 'Category',
        createdAt: 'Created At',
        createdBy: 'Created By',
        data: 'Data',
        document: 'Document',
        field: 'Field',
        internalNote: 'Internal Note',
        interval: 'Interval',
        item: 'Item',
        name: 'Name',
        nrOfAllowedCompaniesByPricingPlan: 'Number Of Allowed Companies By Pricing Plan',
        nrOfAllowedUsersByPricingPlan: 'Number Of Allowed Users By Pricing Plan',
        price: 'Price',
        rootAdminEmail: 'Root-Admin Email',
        rootAdminName: 'Root-Admin Name',
        status: 'Status',
        subject: 'Subject',
        tenantName: 'Tenant',
        type: 'Type',
        value: 'Value',
        valueMonthly: 'Value Monthly',
        valueOneOff: 'Value OneOff',
        valueYearly: 'Value Yearly',
      },
      company: {
        addCompany: 'Add company',
        changeCompany: 'Replace company',
        editCompany: 'Edit Company',
      },
      contact: {
        addContact: 'Add contact',
        deleteContact: 'Delete contact',
        editContact: 'Edit contact',
      },
      dataPicker: {
        thisYear: 'This year',
      },
      duplicatedErrorMessage: '{{name}} with the same name already exists',
      error: 'Error',
      fields: {
        actions: {
          label: 'Actions',
        },
        benefits: {
          label: 'Benefits',
        },
        body: {
          label: 'Body',
          placeholder: 'Body',
        },
        calculationMode: {
          label: 'Item Calculation Mode',
        },
        defaultValue: {
          label: 'Default value',
        },
        descriptionForContract: {
          label: 'Description for contract',
        },
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
        guidanceText: {
          label: 'Guidance text',
          placeholder: 'Guidance text',
        },
        guidanceVideoId: {
          label: 'Guidance video',
          placeholder: 'Guidance video',
        },
        highlightBindingness: {
          label: 'Bindingness Mode',
        },
        infoText: {
          label: 'Info',
        },
        introForDocument: {
          label: 'Intro for document',
        },
        isDefault: {
          label: 'Is default',
        },
        minPrice: {
          label: 'Minimal Price',
        },
        name: {
          label: 'Name',
          placeholder: 'Name',
        },
        nameRaw: {
          label: '$t(common.fields.name.label)',
        },
        notesForFeeAgreement: {
          label: 'Fee details for fee-agreement',
        },
        notesToEmployee: {
          label: 'Notes to employee',
        },
        nrOfAllowedCompaniesByPricingPlan: {
          label: 'Number Of Allowed Companies By Pricing Plan',
          placeholder: 'Number Of Allowed Companies By Pricing Plan',
        },
        nrOfAllowedUsersByPricingPlan: {
          label: 'Number Of Allowed Users By Pricing Plan',
          placeholder: 'Number Of Allowed Users By Pricing Plan',
        },
        password: {
          label: 'Password',
          placeholder: 'Password',
        },
        passwordConfirmation: {
          label: 'Password confirmation',
          placeholder: 'Password confirmation',
        },
        paymentInterval: {
          label: 'Payment Interval',
        },
        pleaseNote: {
          label: 'Please note',
        },
        position: {
          label: 'Position',
          placeholder: 'Position',
        },
        pricingFormula: {
          label: 'Pricing Formula',
        },
        projectName: {
          label: 'Project name',
        },
        role: {
          label: 'Role',
          placeholder: 'Role',
        },
        status: {
          label: 'Status',
        },
        subject: {
          label: 'Subject',
          placeholder: 'Subject',
        },
        type: {
          label: 'Type',
        },
        value: {
          label: 'Value',
        },
        valueRaw: {
          label: '$t(common.fields.value.label)',
        },
      },
      howUseButton: "Help? Let's get started",
      inputs: {
        otp: {
          title: 'One Time Password',
        },
      },
      navigationButtons: {
        done: 'Done',
        import: 'Import',
        next: 'Next',
        previous: 'Previous',
      },
      next: 'Next',
      no: 'No',
      off: 'Off',
      ok: 'Ok',
      on: 'On',
      quotes: [
        {
          quote: 'Gut zuhören können ist der halbe Erfolg',
        },
        {
          quote: 'Zeige deinen Mandanten, wie sehr du dich um sie kümmerst',
        },
        {
          quote: 'Persönlicher Service gewinnt das Spiel',
        },
        {
          author: 'Angelehnt an Mahatma Gandhi',
          quote: 'Ein Mandant ist der wichtigste Besucher in unserem Hause',
        },
        {
          author: 'Chip Bell',
          quote: 'Loyale Mandanten bestehen darauf, dass auch ihre Freunde mit dir Geschäfte machen',
        },
        {
          quote: 'Wir hören gut zu und liefern echten Mehrwert',
        },
        {
          quote: 'Tue Gutes und rede darüber',
        },
      ],
      roles: {
        ADMIN: 'Admin',
        ROOT_ADMIN: 'Root Admin',
        SUPER_ADMIN: 'Super Admin',
        USER: 'User',
        VIEWER: 'Viewer',
      },
      saved: 'Saved',
      saving: 'Saving',
      showDigits: {
        always: 'always show full digits (p.e. 10,00 €)',
        avoid: 'only show full digits for uneven numbers (p.e. 10 € or 8,99 €)',
      },
      stateInvitation: {
        ACTIVE: 'Active',
        INACTIVE: 'Inactive (left company)',
        INVITATION_SEND: 'Invitation sent',
      },
      statusTypes: {
        shoppingCart: 'Shopping Cart',
      },
      success: 'Success!',
      successToastText: '$t(common.success)',
      tenant: {
        noAllowedToCreateCompany: 'Not allowed to create more companies',
      },
      toast: {
        errors: {
          activateInvitation: 'Failed to activate',
          passwordReset: 'Failed to send password reset e-Mail',
          resendInvitation: 'Failed to resend invitation',
          sendInvitation: 'Failed to send invitation',
          user: {
            delete: 'Failed to delete user',
          },
        },
        info: {
          updateIntroductionBlock:
            'Some persons have been deleted, so we will automatically update the Introduction block data',
        },
        success: {
          acceptInvitation: 'Invitation successfully accepted',
          activateInvitation: '{{name}} has been activated',
          changePassword: 'Password changed successfully',
          clipboard: 'Copied to clipboard',
          passwordReset: 'E-Mail sent',
          resendInvitation: 'Invitation resent',
          sendChangeEmailVerificationEmail:
            'Please check your email, {{email}}, for further instructions on changing your email.',
          sendChangePasswordVerificationEmail:
            'Please check your email, {{email}}, for further instructions on resetting your password.',
          sendInvitation: 'Invitation sent',
        },
      },
      unsavedChangesModal: {
        cancel: '$t(common.cancel)',
        proceed: 'Proceed',
        title: 'The changes will not be saved. Proceed?',
      },
      urls: {
        calendlyUrl:
          'https://calendly.com/kanzleipilot-erfolgsteam/kanzleipilot-fragen-und-antworten-session-15-minuten',
        sendEmailUrl: 'mailto:support@kanzleipilot.de',
      },
      vatType: {
        company: 'Company VAT',
        personal: 'Personal VAT',
      },
      yes: 'Yes',
    },
    dateFormats: {
      date: 'dd/MM/yyyy',
      dateMoment: 'DD/MM/YYYY',
      dateTime: 'dd/MM/yyyy HH:mm',
      month: 'MM/yyyy',
    },
    decimalSeparator: '.',
    deleteDocumentTemplateConfirmation: {
      cancel: '$t(common.cancel)',
      documentIsUsed:
        'Certain documents or settings use this document template. Are you sure that you want to remove it? (Existing documents don`t get deleted.)',
      ok: '$t(common.ok)',
      title: 'Are you sure you want to delete the document template?',
    },
    deleteEmailTemplateConfirmation: {
      cancel: '$t(common.cancel)',
      documentIsUsed: 'Certain settings use this email template. Are you sure that you want to remove it?',
      ok: '$t(common.ok)',
      title: 'Are you sure you want to delete the email template?',
    },
    deleteShoppingCartConfirmation: {
      cancel: '$t(common.cancel)',
      ok: '$t(common.ok)',
      title: 'Are you sure you want to delete the shopping cart?',
    },
    duplicateShoppingCartConfirmation: {
      cancel: '$t(common.cancel)',
      ok: '$t(common.ok)',
      title: 'Do you want to store a duplicate of the shopping-cart as a new shopping-cart instance?',
    },
    editShoppingCartModal: {
      HelperWidget: {
        howUseButton: 'Learn about different between global and independent',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Learn about different between global and independent',
          videoCaption: 'Learn how to use independent and global modes',
          videoUrl: 'https://vimeo.com/715925363',
        },
      },
      explanationGlobal: 'You use the new configuration. (The settings are administrated by users with admin-rights)',
      explanationIndependent:
        'You use the configuration, which was active at the time of the last shopping cart revision creation',
      globalOption: 'Edit with global configuration',
      independentOption: 'Edit with independent configuration',
      labelGlobal: 'Global configuration',
      labelIndependent: 'Independent configuration',
      migrationInfoText:
        'This shopping cart was migrated before saving of "independent configurations" and therefore you can\'t edit it with "independent configuration". You have to edit this shopping cart one time with the "global configuration" to can use the "independent configuration".',
      title: 'Which configuration do you want to use for editing the shopping cart?',
    },
    email: {
      transactionalBadRequest: 'Email could not be send, please validate your input',
      transactionalUnkownError: 'Email could not be send, please try again later',
    },
    locale: 'English',
    localeCode: 'en',
    onboarding: {
      companyInfo: {
        stepTitle: 'Company info',
        textAboveVideo: '',
        title: 'Company info',
      },
      corporateIdentity: {
        chooseFontsTitle: 'Choose your company fonts',
        colorInputLabel: '$t(admin.Setting.CorporateIdentity.CorporatePrimaryColor.inputs.color)',
        colorPreview: 'Preview of button (Primary + Contrast)',
        fontFamilyInputLabel: 'Pick your text font',
        pickCompanyColors: 'Pick your company colors',
        preview: 'preview',
        primaryColorInputLabel: '$t(admin.Setting.CorporateIdentity.CorporatePrimaryColor.inputs.primaryColor)',
        stepTitle: 'Corporate Identity',
        textAboveVideo: '',
        textFontPreview:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad',
        title: 'What does your company look like?',
        titleFontFamilyInputLabel: 'Pick your title font',
        titleFontPreview: 'This is a nice title',
        uploadLogoAlert: 'Please upload your logo in PNG or JPG format with maximal 1000 pixel height and width.',
        uploadLogoTitle: 'Upload your company logo',
        videoCaption: 'Henning explains CI',
        videoUrl: 'https://vimeo.com/163721649',
      },
      finish: {
        buttonsTitle: 'What do you want to do next?',
        calendly: 'Book Onboarding-Session (via Calendly)',
        calendlyUrl: '$t(common.urls.calendlyUrl)',
        catalogueConfigurationButton: 'Check items (and adjust pricing)',
        manageUsersButton: 'Invite your team-mates',
        shoppingCartButton: 'Create a first shopping cart',
        stepTitle: 'Finish',
        successfulMessage: 'Successful tenant registration',
        textAboveVideo: 'Hello {{userFirstName}}, congratulations! You are done!',
        title: 'Done and ready to go!',
        videoCaption: 'Henning talks next steps',
        videoUrl: 'https://vimeo.com/163721649',
      },
      navigationButtons: {
        done: '$t(common.navigationButtons.done)',
        next: '$t(common.navigationButtons.next)',
        previous: '$t(common.navigationButtons.previous)',
      },
      pickCategories: {
        stepTitle: 'Pick Categories',
        textAboveVideo: '',
        title: 'Pick your categories',
        videoCaption: 'Henning talks pricing levels',
        videoUrl: 'https://vimeo.com/163721649',
      },
      pickDocuments: {
        infoConfirmationMessage: 'Please note that those documents are just templates. Please press ok (ij)',
        infoMessage:
          'Important: we do not warranty legal correctness of those documents. They are just templates for you to build on.',
        stepTitle: 'Pick Documents',
        textAboveVideo: '',
        title: 'Pick your documents',
        videoCaption: 'Henning talks document',
        videoUrl: 'https://vimeo.com/163721649',
      },
      pricingLevel: {
        hourlyRatesTable: {
          accountant: 'Accountant',
          employee: 'Employee',
          jobType: 'Job type',
          pricingFactor: 'Pricing Factor',
          title: 'What are the hourly rates?',
        },
        otherSettingsTable: {
          tenantSoftware: 'Customerportal name',
          title: 'Other settings',
          value: 'Value',
          variable: 'Variable',
        },
        pricingTable: {
          category: 'Category',
          loans: 'Loans',
          monthlyBookkeeping: 'Monthly Bookkeeping',
          pricingFactor: 'Pricing Factor',
          title: '',
          yearlyBusinessAccountsBalanceSheetBEU: 'Pricing level yearly business accounts (BEU)',
          yearlyBusinessAccountsBalanceSheetRest: 'Pricing level yearly business accounts (rest)',
          yearlyBusinessAccountsCompanies: 'Pricing level yearly business accounts (EÜR companies)',
          yearlyBusinessAccountsSingle: 'Pricing level yearly business accounts (EÜR)',
          yearlyPersonalAccounts: 'Yearly Personal Accounts',
        },
        stepTitle: 'Pricing Level',
        textAboveVideo: '',
        title: 'What is your pricing level?',
        videoCaption: 'Henning talks pricing levels',
        videoUrl: 'https://vimeo.com/163721649',
      },
      pricingMode: {
        fixedMonthlyModeDescription:
          'One-time services are priced transparently. Monthly and yearly items are summed up to a monthly fixed fee. Gives your customer planning reliabilty.',
        fixedMonthlyModeTitle: 'Monthly Fixed Fee',
        standardModeDescription:
          'Shows prices of all positions and all interval-types. This is what 80 % of accountants work in',
        standardModeTitle: 'Transparent Mode',
        stepTitle: 'Pricing Mode',
        textAboveVideo: '',
        title: 'Which pricing-mode do you work in?',
        videoCaption: 'Henning talks pricing levels',
        videoUrl: 'https://vimeo.com/163721649',
      },
      signatures: {
        addButton: 'Add',
        per: ' must have at least 1 person',
        stepTitle: 'Signatures',
        submitError: 'Please, add at least 1 person',
        textAboveVideo: 'Here you can add signatures which will appear at the introduction of a document',
        title: 'Persons authorized to sign',
        videoCaption: 'Henning talks signatures',
        videoUrl: 'https://vimeo.com/163721649',
      },
      welcome: {
        stepTitle: 'Welcome',
        textAboveVideo:
          "Hello {{userFirstName}}, let's install KanzleIPilot for your company! It's gonna take 5 minutes of your time. We'll ask you a few questions and then automatically populate your catalogue and pricing",
        title: 'Welcome to KanzleiPilot',
        videoCaption: 'Henning says hi',
        videoUrl: 'https://vimeo.com/163721649',
      },
    },
    sharedPackage: {
      actualCostCalculation: {
        include: 'Includes',
        plus: 'Plus',
      },
      code: '{{paymentInterval}} {{discountedValue}}$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice:
        '{{paymentInterval}} {{discountedCustomPrice}}$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice_discount:
        '{{discountedCustomPrice}} {{paymentInterval}}{{newlineOrWhitespace}}discounted by {{discount}} <i>(original price <del>{{customPrice}}</del>)</i>$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice_showCalculatedPrice:
        '{{paymentInterval}} {{customPrice}} <i>(instead of calculated price <del>{{discountedValue}}</del>)</i>$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice_showCalculatedPrice_discount:
        'Your custom price: {{paymentInterval}} {{discountedCustomPrice}} <i>(discounted {{discount}}, your custom price before discount <del>{{customPrice}}</del>, Calculated price <del>{{priceBeforeDiscount}}</del>)</i>$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_discount:
        '{{discountedValue}} {{paymentInterval}}{{newlineOrWhitespace}}discounted by {{discount}} <i>(original price <del>{{priceBeforeDiscount}}</del>)</i>$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_discount_noDiscountValue:
        '{{discountedValue}} {{paymentInterval}} <i>(instead of <s>{{priceBeforeDiscount}}</s> due to discounts)</i>',
      code_on_actual_cost: '{{paymentInterval}} on actual cost',
      empty_pricing_formula: 'empty pricing formula NOT allowed',
      fixedMonthlyFeeHint:
        '{{paymentInterval}} price is included in "fixed monthly price" listed below in "Final Price Preview" section$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      fixedMonthlyFeeHint_discount:
        '{{paymentInterval}} price is included in "fixed monthly price" listed below in "Final Price Preview" section$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      free_service: 'free {{paymentInterval}} service$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      highlightBindingness: {
        dynamic: '<br/><i>$t(common.Item.highlightBindingness.{{highlightBindingness}})</i>',
        estimated: '$t(sharedPackage.highlightBindingness.dynamic)',
        fixPriced: '$t(sharedPackage.highlightBindingness.dynamic)',
        noHighlight: '',
      },
      numberOfActualCost_one:
        '$t(sharedPackage.actualCostCalculation.{{actualCostCalculation}}) {{count}} item on actual cost',
      numberOfActualCost_other:
        '$t(sharedPackage.actualCostCalculation.{{actualCostCalculation}}) {{count}} items on actual cost',
      numberOfHighlightBindingness_one:
        '{{count}} item with $t(common.Item.highlightBindingness.{{highlightBindingness}}Dativ) value {{value}}',
      numberOfHighlightBindingness_other:
        '{{count}} items with $t(common.Item.highlightBindingness.{{highlightBindingness}}Dativ) values {{value}}',
      variable_is_empty: '{{variable}} is empty',
    },
    signing: {
      common: {
        backButton: 'Back',
        continueButton: 'Continue',
        headings: {
          BOOKMARKED_ITEMS: 'Recommendation',
          IDENTITY: 'Confirm Identity',
          MONTHLY_PAYMENT: '',
          SEPA: 'Grant SEPA mandate',
          SIGNING: 'View and sign your documents',
          VIEW_SHOPPING_CART: 'Check services',
          ready: 'Ready',
        },
        steps: {
          BOOKMARKED_ITEMS: 'Recommendation',
          IDENTITY: 'Confirm Identity',
          MONTHLY_PAYMENT: 'Monthly Payment',
          SEPA: 'Grant SEPA mandate',
          SIGNING: 'Sign',
          VIEW_SHOPPING_CART: 'Check services',
          ready: 'Ready',
        },
      },
      identityStep: {
        errors: {
          noOptionSelected: 'You have to confirm, that you are the called person or you have to select No.',
        },
        heading: 'Confirm Identity',
        options: {
          company: {
            no: '<b>No</b>, I am not {{name}} or I don’t represent {{company}}.',
            yes: '<b>Yes</b>, I am {{name}} and do represent {{company}}.',
          },
          private: {
            no: '<b>No</b>, I am not {{name}}.',
            yes: '<b>Yes</b>, I am {{name}}.',
          },
        },
        question: {
          company: 'Are you {{name}} and do you represent {{company}}?',
          private: 'Are you {{name}}?',
        },
        warning: {
          description:
            'This and the following pages are only allowed for a special person. Please confirm, that you are {{name}} and you do represent {{company}}. Shouldn`t you be this person, then select the option “No”.',
          message: 'Important note:',
        },
      },
      infoPages: {
        alreadySigned: {
          heading: 'You have already signed the documents',
        },
        signingNotValid: {
          heading: 'This link isn`t valid',
        },
        startPage: {
          errors: {
            passwordWrong: 'The password is wrong!',
          },
          heading: 'View and sign your documents',
          password: {
            label: 'Password',
            placeholderText: 'Password',
          },
          passwordAlert: {
            description:
              'The following pages are only accessable by a password. You get your password with an e-mail or an other way by your tenant. If you have no password yet, then contact your tenant.',
            message: 'Important note',
          },
          startButton: 'Start now',
        },
        successfullySigned: {
          heading: {
            client:
              'Thank you. You are ready with your signing process. When all signees have signed, we will send you all signed documents. You can close this tab now.',
            tenant:
              'Thank you. You have finished your signing flow. You get an e-mail with all signed documents after all signees have signed. You can close the tab now.',
          },
        },
        waitForDocuments: {
          heading: 'Documents are in preparation<br/>Please wait',
        },
        waitForDocumentsFailed: {
          heading:
            'The preparation of the documents failed. Please contact your tenant how to continue: {{officeEmail}}.',
        },
        waitForDocumentsTimeout: {
          heading:
            'The preparation of the documents isn`t finished yet. Try to sign in one hour with the following link: <a>{{url}}</a>. If the documents aren`t ready on this point, then contact your tenant: {{officeEmail}}',
        },
        wrongIdentityForSigning: {
          heading:
            'Thank you for your feedback<br/>If you got this link by email, then please delete the email. It isn`t for you.',
        },
      },
      monthlyPaymentDecisionStep: {
        defaultHeadline: 'Configure Monthly Payment',
        errors: {
          noOptionSelected: 'You must choose whether you want to pay for annual services monthly or not.',
        },
        options: {
          no: 'No, I would like to pay for monthly and annual services separately.',
          yes: 'Yes, I would like to pay for annual services monthly in smaller amounts.',
        },
        warning: {
          description:
            'Please select here if you would like to distribute the fee for annual services over the months.',
          monthlyPaymentStepDone:
            'A previous signee has already made a selection. You can check the selection and if you disagree or find a mistake, then you can contact your tenant to inform and exchange about the next steps.',
        },
      },
      sepaMandateStep: {
        accountOwnerName: {
          errors: {
            emptyInput: 'Please input the name of the account owner',
            wrongFormat: 'Please input first and last name of the account owner. (Example: Max Mustermann)',
          },
          label: 'Please input the name of the account owner of the used bank account:',
          placeholder: 'Account owner',
        },
        bankData: {
          label: 'Bank',
        },
        bic: {
          errors: {
            emptyInput: 'Please input the BIC. If you only have a bank code, then you can let convert this into a BIC.',
            wrongFormat: 'The inputted BIC has the wrong format. The BIC must have eight or eleven digits.',
          },
          label: 'Please input the BIC of the used bank account:',
          placeholder: 'BIC',
        },
        grantSepa: {
          errors: {
            mandatory: 'SEPA mandate is mandatory.',
          },
          no: 'No, I wouldn’t grant a SEPA mandate the tenant {{tenantName}} for this contract.',
          yes: 'Yes, I would like to grant the tenant {{tenantName}} a SEPA mandate for this contract.',
        },
        iban: {
          errors: {
            emptyInput: 'Please input the IBAN',
            wrongFormat:
              'The inputted IBAN has the wrong format. The IBAN must start with a two digits country code (like ‘DE’). The IBAN must have at least 13 and max 34 digits.',
          },
          label: 'Please input the IBAN of the used bank account:',
          placeholder: 'IBAN',
        },
        stepAlreadyDone:
          'A previous signee has already made a selection. You can check the selection and if you disagree or find a mistake, then you can contact your tenant to inform and exchange about the next steps.',
        stepNotDoneYet: 'The client has not yet made a choice.',
      },
      serviceOverviewStep: {
        fixedFeeInfo: 'Service is included in "fixed monthly price", {{paymentIntervalText}}',
        fixedMonthly: 'fixed monthly price',
        introtext: {
          text: {
            client: 'Please check the services and click on "Continue"',
          },
        },
        modal: {
          fixedFeeInfo: 'Service is included in "fixed monthly price"',
          priceHeading: 'Price:',
        },
        onActualCostsBadge: 'on actual cost',
        onActualCostsTooltip: 'This is a service on actual cost. The price of the service is variable.',
        priceCard: {
          discounts: {
            discountItem: '{{discount}} in category {{name}}',
            message: 'Active discounts',
          },
          heading: 'Investment',
          pricingTable: {
            discountedInformation: '<b>price discounted, instead of <s>{{priceWithoutDiscount}}</s></b>',
            interval: 'Interval',
            price: 'Price',
            vatInformation: 'includes {{vatAbsoluteValue}} $t(common.vatType.{{vatType}}) ({{vatValue}}%)',
          },
          vatInfo: {
            noVat: 'Prices are netto prices plus {{vatValue}}% $t(common.vatType.{{vatType}}).',
            vatIncluded: 'Prices are brutto, they contain {{vatValue}}% $t(common.vatType.{{vatType}}).',
          },
        },
        service: {
          customPriceInformation: '(instead of calculated price <s>{{valueWithoutDiscount}}</s>)',
          discountInformation:
            '(discounted by {{discountValueWithUnit}}, orginal price <s>{{valueWithoutDiscount}}</s>)',
          discountedCustomPriceInformation:
            '(discounted by  {{discountValueWithUnit}}; custom price before discount <s>{{customPriceWithoutDiscount}}</s>, instead of calculated price <s>{{calculatedPrice}}</s>)',
          noteForClient: 'Note: ',
        },
      },
      viewDocumentsAndSignStep: {
        agreementsForm: {
          checkDocumentsSeen: 'Yes, I have read and understood all documents',
          checkNoticeOfCancellation: 'Yes, I confirm, that I read the notice of cancellation and I agree it.',
          errorNotSelectedYet:
            'You haven`t agreed all conditions. You have to agree all conditions before you can sign the documents.',
          pleaseAgree: {
            client: 'Please confirm, that you read the documents and you agree them.',
            tenant: 'Please confirm, that you read the documents and you agree them.',
          },
          stepHeadline: '2: Confirm that you have read the documents',
        },
        documentsCard: {
          downloadButton: 'Download',
          showButton: 'Show',
          stepHeadline: '1: View the documents',
          title: 'Documents',
        },
        introtext: {
          headline: 'Now please do the following:',
          text: '1. View all documents (by clicking on "Show")<br/>2. Confirm that you have read all documents<br/>3. Please sign the documents by clicking on the signature field below',
        },
        signeesCard: {
          notSignedYet: 'not signed yet',
          signed: 'signed at {{date}}',
          title: 'Signature State',
        },
        signingButton: {
          label: 'Click here to sign all documents',
          notSignedYet: 'You haven`t signed the documents. You have to sign the documents before you can continue.',
          signing: 'Sign documents...',
          stepHeadline: '3: Sign all documents with one click',
        },
      },
    },
    superAdmin: {
      ManageAllUsers: {
        title: 'Manage All Users',
      },
      ManageDigitalSignature: {
        defaultTemplatesCard: {
          infoAlert: {
            description:
              'Here you can set default templates for the template settings of the digital signature. This templates can be imported by press by the tenant and set as selection of their digital signature settings. If one selection here will be empty, then for this settings no template will be imported and the settings will be set to not selected, if the tenant resets its digital signature settings.',
            title: 'Note:',
          },
          noticeOfCancellationTemplate: {
            label: 'Default document template for notice of cancellation',
            placeholder: 'No template selected',
            tooltip: 'Document template of the commonlibrary, which should be imported for the notice of cancellation',
          },
          openedBySigneeNotificationTemplate: {
            label:
              'Default email template for notification of the tenant signees about the opening of the signing process by signee',
            placeholder: 'No template selected',
            tooltip:
              'Email template of the commonlibrary, which should be imported for the notification email towards tenant signees about the opening of a signing process by signee of the same digital signature process',
          },
          passwordEmailTemplate: {
            label: 'Default email template for digital signature password email',
            placeholder: 'No template selected',
            tooltip:
              'Email template of the commonlibrary, which should be imported for the password email for digital signature',
          },
          remindEmailTemplate: {
            label: 'Default email template for digital signature reminder email',
            placeholder: 'No template selected',
            tooltip:
              'Email template of the commonlibrary, which should be imported for the reminder email for digital signature',
          },
          revokeEmailTemplate: {
            label: 'Default email template for revoke digital signature notification',
            placeholder: 'No template selected',
            tooltip:
              'Email template of the commonlibrary, which should be imported for revoke digital signature notification email',
          },
          sepaMandateTemplate: {
            label: 'Default document template for SEPA mandate',
            placeholder: 'No template selected',
            tooltip: 'Document template of the commonlibrary, which should be imported for the SEPA mandate',
          },
          signedBySigneeNotificationTemplate: {
            label: 'Default email template for notification of signees about the signing by signee',
            placeholder: 'No template selected',
            tooltip:
              'Email template of the commonlibrary, which should be imported for the notification email towards signees about the signing of a signing process by signee of the same digital signature process',
          },
          startClientNotificationTemplate: {
            label: 'Default email template for the start email of the digital signature for client signees',
            placeholder: 'No template selected',
            tooltip:
              'Email template of the commonlibrary, which should be imported for the start email for the digital signature for client signees',
          },
          startTenantNotificationTemplate: {
            label: 'Default email template for the start email of the digital signature for tenant signees',
            placeholder: 'No template selected',
            tooltip:
              'Email template of the commonlibrary, which should be imported for the start email for the digital signature for tenant signees',
          },
          successfulFinishedNotificationTemplate: {
            label: 'Default email template for the successful finished email for the digital signature',
            placeholder: 'No template selected',
            tooltip:
              'Email template of the commonlibrary, which should be imported for the successful finished email for the digital signature',
          },
          title: 'Default templates for digital signature',
        },
        digitalSignatureKillSwitch: {
          switchLabel: 'Enable digital signature globally',
          title: 'Global digital signature state',
          warningSwitchOff:
            'If you put the digital signature off globally, then no tenant can use the digital signature anymore. All active digital signature processes get revoked and no signee can use his signing process.',
          warningSwitchOn:
            'If you put the digital signature on globally, all tenants can switch the digital signature on.',
        },
        statistics: {
          currentlyOpenDigitalSignatureProcesses: 'Currently open digital signature processes',
          currentlyOpenSigningProcesses: 'Currently open signing processes',
          finishedDigitalSignatureProcesses: 'Total number of finished digital signature processes',
          finishedDigitalSignatureProcessesThisMonth: 'Number of finished digital signature processes in this month',
          finishedSigningProcesses: 'Total number of finished signing processes',
          finishedSigningProcessesThisMonth: 'Number of finished signing processes in this month',
          numberOfTenantWithEnabledDigitalSignature: 'Tenants with enabled digital signature',
          numberOfTotalTenants: 'Total tenants',
          openedDigitalSignatureProcesses: 'Total number of started digital signature processes',
          openedDigitalSignatureProcessesThisMonth: 'Number of opened digital signature processes in this month',
          openedSigningProcesses: 'Total number of started signing process',
          openedSigningProcessesThisMonth: 'Number of opened signing processes in this month',
          title: 'Stats',
        },
        title: 'Manage digital signature globally',
      },
      ManageTenants: {
        addTenant: 'Add tenant',
        addTenantModalTitle: 'Create a new tenant',
        columns: {
          licenseStarted: 'License Started',
          locale: 'Locale',
          nrOfAllowedCompaniesByPricingPlan: '$t(common.columns.nrOfAllowedCompaniesByPricingPlan)',
          nrOfAllowedUsersByPricingPlan: '$t(common.columns.nrOfAllowedUsersByPricingPlan)',
          nrOfCatalogueConfigurationEdits: 'Number Of  "Edits" In Catalogue-Configuration',
          nrOfCompanies: 'Number Of Companies',
          nrOfCompanyTypeEdits: 'Number Of  "Edits" In DocumentType-Configuration',
          nrOfCreatedShoppingCarts: 'Number Of Shopping-Carts Created',
          nrOfLogins: 'Number Of Logins',
          nrOfTotalActions: 'Number Of Total Actions',
          nrOfUsers: 'Number Of Users',
          rootAdminEmail: '$t(common.columns.rootAdminEmail)',
          rootAdminName: '$t(common.columns.rootAdminName)',
          status: '$t(common.columns.status)',
          tenantName: '$t(common.columns.tenantName)',
          tenantStatus: 'Tenant Status',
          timeStampOfLastAction: 'Timestamp Of Last Action',
          viewLogbook: 'View Logbook',
        },
        editTenantModalTitle: 'Edit tenant',
        emptyListMessage: "There are no tenant's actions yet",
        fields: {
          disabled: {
            label: 'Disable Tenant',
            values: {
              disabled: 'Disabled',
              enabled: 'Enabled',
            },
          },
          licenseStarted: {
            label: 'License Started',
          },
          locale: {
            label: 'Locale',
            placeholder: 'Locale',
            values: {
              de: 'German',
              en: 'English',
            },
          },
          nrOfAllowedCompaniesByPricingPlan: {
            errorMessage: 'Number Of Allowed Companies By Pricing Plan is a required field',
            label: '$t(common.fields.nrOfAllowedCompaniesByPricingPlan.label)',
            placeholder: '$t(common.fields.nrOfAllowedCompaniesByPricingPlan.placeholder)',
          },
          nrOfAllowedUsersByPricingPlan: {
            errorMessage: 'Number Of Allowed Users By Pricing Plan is a required field',
            label: '$t(common.fields.nrOfAllowedUsersByPricingPlan.label)',
            placeholder: '$t(common.fields.nrOfAllowedUsersByPricingPlan.placeholder)',
          },
          rootAdminEmail: {
            label: 'Email of Root-Admin',
            placeholder: 'Email of Root-Admin',
          },
          rootAdminFirstName: {
            label: 'Root-Admin First Name',
            placeholder: 'Root-Admin First Name',
          },
          rootAdminLastName: {
            label: 'Root-Admin Last Name',
            placeholder: 'Root-Admin Last Name',
          },
          tenantName: {
            label: 'Company',
            placeholder: 'Company',
          },
        },
        loadingTenantsActions: "Loading tenant's actions",
        logBookModalTitle: 'Tenant\'s actions of the "{{tenantName}}"',
        stateInvitation: {
          ACTIVE: 'Active',
          INACTIVE: 'Inactive',
          INVITATION_SEND: 'Invitation sent',
        },
        title: 'Manage Tenants',
      },
    },
    user: {
      CompareToGlobal: {
        changed: {
          drawer: {
            global: 'Global version',
            independent: 'Independent version',
            label: 'The independent configuration differs from global configuration',
            notSet: 'n/a',
          },
          label: 'The independent configuration differs from global configuration. Please click to compare',
        },
        deleted: {
          label: 'This element was removed from global configuration',
        },
        new: {
          label: 'This element was added to global configuration',
          staticItems: 'Static items added to global (and not available here)',
        },
      },
      MigratedHelperWidget: {
        howUseButton: 'Learn what this means',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Learn what this means',
          videoCaption: '',
          videoUrl: 'https://vimeo.com/667201315',
        },
      },
      PrintShoppingCartPage: {
        compareTitle:
          'Global document-template was updated AFTER {{createdAt}}-date of previous valse-revision of independent SCR. Please be aware that elements might be differ.',
        download: 'Download',
        downloadAll: 'Download all',
        downloadDocuments: 'Download Documents',
        fields: {
          actions: '$t(common.fields.actions.label)',
          name: '$t(common.fields.name.label)',
        },
        print: 'Print',
        signed: 'Signed',
        stateLoading: {
          download: 'Download Pdf',
          generate: 'Generating Pdf',
          save: 'Saving Pdf',
        },
        successfulMessage: 'Entry has been stored',
      },
      SendShoppingCartPage: {
        loadingMessage: 'Sending email...',
        successfulMessage: 'E-Mail successfully sent',
      },
      Setting: {
        AccountSetting: {
          tabs: {
            main: {
              cancelChangeEmail: '$t(common.cancel)',
              changeEmail: 'Change Email',
              fields: {
                email: 'Email',
                firstName: 'First name',
                lastName: 'Last name',
                newEmail: 'New email',
                otp: 'One Time Password',
                phone: 'Phone',
                position: 'Position',
              },
              saveEmail: 'Save Email',
              title: 'Main',
            },
            password: {
              errors: {
                currentPassworWrong: 'Password is incorrect',
                noMatch: 'Passwords must match',
              },
              fields: {
                currentPassword: 'Current password',
                newPassword: 'New password',
                otp: 'One Time Password',
                passwordConfirmation: 'Password confirmation',
              },
              savePassword: 'Save password',
              title: 'Password',
            },
            variables: {
              title: 'Variables',
            },
          },
          title: 'Edit Account',
        },
      },
      ShoppingCart: {
        Buttons: {
          email: 'Save and open email editor',
          errors: {
            formHasErrors: 'There are validation-errors in the form. Please correct',
            noCompanyError: 'Please add company to enable those buttons',
            noContactsError: 'Please add contact to enable those buttons',
            noItemsSelectedError: 'Please select items to enable those buttons',
            noRecipientsWithEmail: 'Please set contact with e-mail address to be able to send e-mail',
          },
          html: 'Preview of delivery slip HTML',
          pdf: 'Preview of delivery slip PDF',
          pdfGenerating: 'PDF is generating. Please wait and retry.',
          pdfNotFound: 'PDF not found. Please contact the support and we will solve the problem.',
          print: 'Save and show documents',
          printModal: {
            title: 'Entry will be stored. Ok?',
          },
          saveAndStartDigitalSignature: 'Save and start digital signature',
          saveAsDraft: 'Save as draft',
          store: 'Save and show shopping cart',
          submitRevisionModal: {
            cancel: '$t(common.cancel)',
            ok: '$t(common.ok)',
            printTitle: 'Do you want to print the shopping cart as PDF?',
            saveAndStartDigitalSignatureTitle:
              'Do you want to save the shopping cart and start the digital signature process afterwards?',
            saveTitle: 'Do you want to save the shopping cart?',
            sendTitle: 'Do you want to save the shopping cart and send an email afterwards?',
          },
        },
        Category: {
          Discount: {
            label: 'Select Discount',
          },
          InputField: {
            mustBeANumberMessage: '{{name}} must be a number',
            requiredMessage: 'Please enter value in {{name}}',
          },
          Item: {
            bookmarkTooltip: 'Mark as strategic recommendation (appears as an optional service)',
            customPriceDrawer: {
              CustomPriceHelperWidget: {
                howUseButton: 'Set prices',
                modalInfo: {
                  helpText: '',
                  imageUrl: '',
                  title: 'Set prices',
                  videoCaption: 'Learn how to set prices',
                  videoUrl: 'https://vimeo.com/693703597',
                },
              },
              cancelConfirmation: 'Your changes will be lost. Continue?',
              deleteCustomPriceConfirmation: 'Are you sure you want to remove the custom price?',
              fields: {
                customPrice: {
                  label: 'Final custom price',
                  tooltip:
                    'If you set an individual price, you will overwrite the calculated price. Please note that active discounts will still be applied to the individual price.',
                },
                defaultValue: {
                  label: '$t(common.fields.defaultValue.label)',
                },
                internalInputField: {
                  errors: {
                    infinity: 'Division by 0 occurred with values of internal input-field(s). Please fix',
                    max: '{{name}} must be ≤ {{value}}',
                    min: '{{name}} must be ≥ {{value}}',
                  },
                  label: 'Customize Internal Inputs',
                  tooltip:
                    'Here you see the list of internal input-fields used in pricing-formula of this item. Here you can customize their value. You can later overwrite the value by entering a "Final custom price".',
                },
                internalNoteToTeam: {
                  label: 'Internal note to team',
                  tooltip:
                    'Here you can add an internal note to the team. What is specific to the customer for this item? The note will be invisible to the customer and only appear in the view-shoppingcart-screen.',
                },
                newPrice: {
                  label: 'New price by customized internal inputs',
                  tooltip:
                    'This price is calculated by pricingFormula and the given parameters (including the internal input values you entered above). If you have set a rounding-preference (globally or for this item) then the rounding is applied here.',
                },
                officialReasonText: {
                  label: 'Official reason text',
                },
                originalPrice: {
                  label: 'Original price',
                  tooltip:
                    'The original price is the price calculated by pricingFormula with the given parameters. If the item does NOT have internal input fields and you have set a rounding-preference (globally or for this item), then the rounding is applied here.',
                },
                showCalculatedPrice: {
                  disabledTooltip:
                    'In monthly fixed mode price is always hidden in documents for monthly and yearly items.',
                  label: 'Show original price?',
                  tooltip:
                    'If show original price is on, then it will be shown next to custom price in service-details block.',
                },
              },
              removeCustomPrice: 'Remove',
              title: 'Edit pricing',
            },
            displayInternalNoteToTeam: 'From user:',
            displayNotesToEmployee: 'From catalogue:',
            displayOfficialReasonText: 'Note:',
            errors: {
              infinityPrice:
                'The price of the item is invalid. Please, check that input field values are entered correctly',
            },
            graduatedScaleMode: 'Scale function is working in graduated-mode',
            infoTextModal: {
              benefits: '$t(common.fields.benefits.label)',
              collapsePanelTitle: 'Show more details',
              descriptionForContract: 'The following services are included',
              notesForFeeAgreement: '$t(common.fields.notesForFeeAgreement.label)',
              notesToEmployee: '$t(common.fields.notesToEmployee.label)',
              pleaseNote: '$t(common.fields.pleaseNote.label)',
            },
            minPrice: 'Minimal Price: {{minPrice}}',
            paymentIntervalAndHighlightBindingness: 'Payment interval: {{paymentIntervalAndHighlightBindingness}}',
            pricingFormula: 'Pricing formula: ',
            referenceError: {
              referenceIsSelectedButInvalid:
                'Please have a look at {{itemName}}. It is invalid and the price calculation depends on it. Please solve.',
              referenceNotSelectedAndInvalid:
                'Please have a look at {{itemName}}. Select it and fill in the input-values.',
              referenceNotSelectedButValid:
                'Please have a look at {{itemName}}. The price-calculation of this item depends on it and it needs to be selected',
            },
            requiredFieldsAlertText: 'Please fill in the marked fields',
            roundPrice: 'The value of the pricing-formula is rounded up in steps of {{step}}',
            selectTooltip: 'Offer and include in the scope of the order',
          },
          StaticItems: 'Show static items',
          Total: {
            label: 'Category total price preview',
            vatTypeLabel: 'Show $t(common.vatType.{{vatType}})',
          },
          ValidationLabels: {
            discount: 'Discount',
            startOfService: 'Start of service',
          },
          showStaticItems: 'Show static items',
          startOfService: 'start of service',
          startOfServiceRequired: 'Start of service is required',
        },
        CompanyType: {
          confirmChange:
            'Are you sure to change selecting? If you change then existing selections will be lost. Change selection?',
          inputs: {
            companyType: {
              label: 'What is your company type?',
              placeholder: 'Select company type',
            },
            selfBooker: 'Are you a self-booker?',
          },
          noTypes: 'No types',
          title: 'Company Type',
        },
        ContactData: {
          buttons: {
            addCompany: '$t(common.company.addCompany)',
            addContact: '$t(common.contact.addContact)',
            changeCompany: '$t(common.company.changeCompany)',
          },
          company: {
            addExistingCompanyTab: 'Add existing company',
            createNewCompanyTab: 'Create new company',
            duplicatedErrorMessage: {
              name: 'Company with the same name already exists',
            },
            empty: 'Add company',
            inputs: {
              city: {
                label: 'City',
                placeholder: 'City',
              },
              cityCode: {
                label: 'Citycode',
                placeholder: 'Citycode',
              },
              commercialObject: {
                label: 'Commercial object',
                placeholder: 'Commercial object',
              },
              company: {
                label: 'Company',
                placeholder: 'Search existing company-name',
              },
              country: {
                label: 'Country',
                placeholder: 'Country',
              },
              houseNumber: {
                label: 'House number',
                placeholder: 'Housenr',
              },
              identifier: {
                label: 'Company identifier',
                placeholder: 'Company identifier',
              },
              name: {
                company: {
                  label: 'Company name',
                  placeholder: 'Company name',
                },
                individual: {
                  label: 'Account name',
                  placeholder: 'Account name',
                },
              },
              street: {
                label: 'Street',
                placeholder: 'Street',
              },
              type: {
                company: 'Company',
                individual: 'Single Person',
              },
            },
            noCountries: 'No countries',
            notFoundContent: {
              create: 'Create a company',
              noFound: 'No companies',
            },
          },
          contact: {
            addExistingContactTab: 'Add existing contact',
            createNewContactTab: 'Create new contact',
            duplicatedContactAlert: {
              editMessage:
                'Please note that the email that you have entered is used by another contact. But you still can proceed with it and the email will be assigned to this contact too',
              message:
                'Continue if you want to create a new contact with the same email and enrich it with different information. If not, then please',
              useExistingContactButton: 'use and assign existing contact instead',
            },
            duplicatedErrorMessage: {
              contact: 'Contact is already imported',
              email: 'Contact with the same email already exists',
            },
            empty: 'Add contact',
            inputs: {
              contact: {
                label: 'Contact',
                placeholder: 'Search existing contact',
              },
              email: {
                label: 'E-mail Address',
                placeholder: 'E-mail Address',
              },
              firstName: {
                label: 'First name',
                placeholder: 'First name',
              },
              fullSalutation: {
                label: 'Full salutation',
                placeholder: 'Full salutation',
              },
              lastName: {
                label: 'Last name',
                placeholder: 'Last name',
              },
              phoneNumber: {
                label: 'Phone-Number business',
                placeholder: 'Phone-Number business',
              },
              phoneNumberAlternative: {
                label: 'Phone-Number alternative',
                placeholder: 'Phone-Number alternative',
              },
              position: {
                label: 'Position',
                placeholder: 'Position',
              },
              salutation: {
                label: 'Gender',
                options: {
                  Mr: 'Mr',
                  Mrs: 'Mrs',
                  Ms: 'Ms',
                },
                placeholder: 'Mr',
              },
            },
            notFoundContent: {
              create: 'Create a contact',
              noFound: 'No contacts',
            },
            title: 'Contacts',
          },
          fetchFromGlobal: 'Update data',
          label: 'Select client',
          modal: {
            cancel: '$t(common.cancel)',
            doNotReplace: "$t(common.no), don't replace",
            ok: '$t(common.ok)',
            replace: '$t(common.yes), replace',
            titles: {
              addCompany: '$t(common.company.addCompany)',
              addContact: '$t(common.contact.addContact)',
              changeCompany: '$t(common.company.changeCompany)',
              changeCompanyContacts:
                'Do you also want to replace set contacts in the current shopping-cart with the default contacts of the company?',
              deleteContact: '$t(common.contact.deleteContact)',
              editCompany: '$t(common.company.editCompany)',
              editContact: '$t(common.contact.editContact)',
            },
          },
          oldData:
            'Data is historic and outdated. Please click the "Update data" button to refresh data for editing company or contact data',
          prefillContact: {
            button: 'Prefill contact with suggested values',
            columns: {
              fieldTitle: '$t(common.columns.field)',
              fields: {
                commercialObject: 'Commercial Object',
                importAcademicTitle: 'Import Academic Title',
                importCompanyType: 'Import Company Type',
                importEmail: 'Import Email',
                importSalutation: 'Import Salutation',
              },
              valueTitle: '$t(common.columns.value)',
            },
            contactCollapse: 'Show hidden import fields',
          },
        },
        DigitalSignature: {
          infoAlert:
            'Note: The signees of the tenant team are notified with the notification for tenant team. You only edit the notification for the client signees.',
          noLinkButtonPresent:
            'No button to start signing process is present in body. Use the button from “Insert Placeholder“ → “Digital Signature“ → “Button for Signing Process“ in the body field to finish the email.',
          onCloseModal: {
            cancelButtonLabel: 'Cancel and don`t start digital signature',
            message:
              'Caution: When you close the window, then the digital signature process won`t be started and the changes on the email get lost. You can start the digital signature process afterwards in the shopping cart view.',
            okButtonLabel: 'Return',
          },
          title: 'Notify the signees of the client about the start of the digital signature process',
        },
        Discounts: {
          activeDiscountsLabel: 'Active Discounts list',
          activeDiscountsListLabel: 'The following discounts are active:',
          activeForCategory:
            '"{{discountName}}" is for "{{categoryName}}" ({{discountValue}} {{discountUnitName}} discount)',
        },
        DocumentTypes: {
          activeTooltip: 'You have set a custom text for {{blockName}}. Click to edit',
          blocks: {
            body: 'Personalized text',
          },
          customPersonalizedText: '{{blockName}}: custom text',
          inactiveTooltip: 'Click to enter a custom {{blockName}}-text',
          label: 'Select Document-types to generate',
          sections: {
            global: 'Global configuration',
            independent: 'Independent configuration',
          },
          typesSelectLabel: 'Which documents should be generated?',
        },
        DocumentTypesTable: {
          actionsHeader: 'Actions',
          nameHeader: 'Document-type',
        },
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/shopping-cart.png',
            title: 'How to use shopping cart',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        NextActions: {
          buttons: {
            backToOffer: 'Go to offer (edit mode will store changes as revision)',
            goToDashBoard: 'Go to Dashboard',
            goToView: 'Go to the view of the shopping cart',
          },
          title: 'Next Actions',
        },
        ProjectInformation: {
          projectLabel: 'Enter project information',
        },
        SendEmail: {
          attachments: 'Attachments',
          attachmentsSwitch: 'Send documents as attachments with e-mail',
          bcc: 'BCC',
          changeEmailTemplatesConfirmation: 'Sure to load a different template? This will overwrite edits. continue?',
          fields: {
            body: {
              label: 'Body',
            },
            emailTemplates: {
              label: 'Email template',
            },
            recipients: {
              label: 'Recipients',
            },
            signature: {
              label: 'Signature',
            },
            subject: {
              label: 'Subject',
            },
          },
          off: 'OFF',
          ok: 'Send E-Mail',
          on: 'ON',
          preview: 'Preview',
          success: 'Email has been sent',
          successfulMessage: 'Entry has been stored',
          title: 'Send E-Mail',
          unablePlaceholdersInBody: '{{placeholders}} will  be available after saving the shopping cart',
        },
        SignatureCard: {
          companySigneesSelect: {
            error: 'You have to select min one contact of company',
            label: 'Who should sign for company?',
          },
          digitalSignatureSwitch: {
            label: 'Enable Digital Signature for this cart',
            off: 'OFF',
            on: 'ON',
          },
          errorMessage:
            'One or more contacts have no email address. Please add an email address to the contacts or remove the contacts from the signee selection.',
          infoMessage:
            'You have bookmarked min one service. This wouldn’t be shown in the signing process or selectable for the signee. This feature will be added in future.',
          label: {
            withDigitalSignature: 'Configure digital signature',
            withoutDigitalSignature: 'Signees',
          },
          monthlyPaymentActiveInfoMessage:
            'You have selected the monthly payment with the optional choice by the client. This is currently NOT supported in the signing process. This feature will be available in the future.',
          tenantSigneesSelect: {
            error: 'You have to select min one user of tenant',
            label: 'Who should sign for your tenant?',
          },
        },
        TotalPricing: {
          TotalTable: {
            headers: {
              VAT: '$t(common.vatType.{{vatType}}) ({{vatValue}} %)',
              interval: 'Interval',
              notes: 'Notes',
              price: 'Price (without vat)',
              priceWithVAT: 'Total price (including vat)',
            },
          },
          error: 'Please fix validation errors above to see price',
          fixedMonthlyFeeLine_monthly: '{{count}} monthly item is selected and is included in the monthly fixed price',
          fixedMonthlyFeeLine_monthly_plural:
            '{{count}} monthly items are selected and are included in the monthly fixed price',
          fixedMonthlyFeeLine_yearly: '{{count}} yearly item is selected and is included in the monthly fixed price',
          fixedMonthlyFeeLine_yearly_plural:
            '{{count}} yearly items are selected and are included in the monthly fixed price',
          fixedMonthlyFeeTypeHint:
            'Prices for selected Monthly and Yearly items are not shown here. They are included in the "fixed monthly price" listed below in "Final Price Preview" section',
          label: 'Investment',
        },
        buttonLabel: 'Shopping Cart Options',
        categoriesCardTitle: 'Select your categories and items',
        compareOptions: {
          global: 'Compare to global configuration',
          previous: '',
        },
        emptyCategoryListMessage: 'There are no categories yet.',
        errorList: {
          scroll: 'Scroll to the right position',
          title: 'Validation errors exist.<br/>Please click below to scroll to the position',
        },
        globalModeAlert: {
          GlobalModeHelperWidget: {
            howUseButton: 'Learn more',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'Learn more',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/713982308',
            },
          },
          title:
            'You have pressed "edit based on global configuration". Our system tries to map all previous independent configuration (categories, items, variables and documents) from {{date}} to the latest global configuration. Please carefully check each position. Once you press "store" the SCR will be stored in independent configuration again with the latest configuration.',
        },
        inputs: {
          companyId: {
            label: 'Company',
          },
          companyTypeId: {
            label: 'Company type',
          },
          contacts: {
            label: 'Contacts',
          },
          email: {
            label: 'E-Mail-Address',
          },
          meetingAt: {
            errorLabel: 'Meeting Date',
            label: 'When was the meeting?',
          },
          name: {
            default: 'Project initiated at {{date}}',
            label: '$t(common.fields.projectName.label)',
          },
          startOfContract: {
            label: 'Start Of Contract',
          },
        },
        options: {
          cancel: '$t(common.cancel)',
          debugMode: {
            label: 'debug mode',
            tooltip:
              'Show the price configuration directly below the service and see how the prices of the individual areas are calculated.',
          },
          feeType: {
            fixedMonthlyFee: 'Fixed monthly fee',
            label: 'Pricing mode',
            standardFee: 'Standard fee',
            tooltip:
              'Calculation mode in which KanzleiPilot calculates the prices. In standard mode everything is the same: one-time, monthly and annual services are displayed transparently. In the entrepreneur fee mode, monthly and annual items are combined at a monthly price and their individual prices are hidden with the note "The service is included in the entrepreneur fee". One-off services are also shown transparently in the company fee.',
          },
          hiddenNote: {
            placeholder: 'Note area',
            title: 'Hidden note area',
          },
          hideHiddenNote: 'Hide hidden note',
          ok: '$t(common.ok)',
          showDiscounts: {
            disabled: 'You can NOT hide discounts because at least 1 discount is active in the shopping cart.',
            label: 'show discounts',
            tooltip:
              "Should discounts be displayed? We recommend you: Switch the display of discounts in the standard OFF and focus on making the client's use clear (see “Settings > Shopping Cart > Shopping cart preferences”).",
          },
          showHiddenNote: 'Show hidden note',
          showPrices: {
            label: 'show prices (of category and items, including discounted prices)',
            tooltip:
              'Should prices be displayed when opening the menu card? We recommend: Switch the price display in the standard OFF (see “Settings > Shopping Cart > Shopping Cart Preferences”).',
          },
          showVat: {
            label: 'show VAT',
            tooltip:
              'If "show VAT" is on, then prices will be shown with vat - in the shopping cart screen itself and also in the generated PDF (Your investment, service details and bookmarked items section)',
          },
          switch: {
            off: '$t(common.off)',
            on: '$t(common.on)',
          },
          title: 'Options',
          vatType: {
            company: '$t(common.vatType.company)',
            label: 'VAT Wording',
            personal: '$t(common.vatType.personal)',
          },
        },
        somethingChangedWhenOpenedEdit: 'Something has changed in the cart, displaying new data',
        titleAlt: 'Tenant Logo',
        validityContract: {
          thisMonth: 'This Month',
          title: 'When does the contract start?',
        },
      },
      lockedIcon: {
        LockHelperWidget: {
          howUseButton: 'Learn more',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Learn more',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        confirmationTitle: 'Data you entered manually is lost. We try to map to global configuration. Continue?',
        editButton: 'Edit based on global configuration',
        title: 'SCR was stored with independent configuration at {{date}}.',
      },
    },
    validation: {
      simplifiedUrl: 'Please enter a valid URL',
    },
    verify: {
      AcceptInvitation: {
        title: 'Accept Invitation',
      },
    },
    viewer: {
      ShoppingCartView: {
        BookedServices: {
          bookmarkedItems: 'Bookmarked items',
          categoryTotals: 'Category Totals',
          items: 'Selected items',
          staticItems: 'Static Items',
        },
        ChangeExpirationDateOnSigningLinkModal: {
          addHours: {
            label: 'Add hours:',
            unit: 'Hours',
          },
          buttons: {
            cancel: 'Cancel',
            ok: 'Do change',
          },
          currentDateLabel: 'Currently available to:',
          newDateLabel: 'After change available to:',
          title: 'Change expiration date of signing link',
        },
        CompareToGlobal: {
          title: 'Compare to global configuration',
          usedVariablesTitle: 'Used variables',
        },
        CreatedDocuments: {
          addDocument: 'Add more documents based on existing shopping-cart',
          addDocumentsModal: {
            placeholder: 'Select existing document templates',
            title: 'Add document templates',
          },
          disabledAddingTooltip: 'You can NOT add document template because its core data has changed.',
          disabledByDigitalSignatureAddingTooltip:
            'You can`t add more documents, because the digital signature is already started for this shopping cart.',
          downloadAllPDFs: 'Download all PDFs',
          error: 'Generation failed',
          noAvailableDocumentsTooltip: 'There is no document template that can be added to the shopping cart.',
          title: 'Created Documents',
          viewPDF: 'View PDF',
        },
        DigitalSignature: {
          additionalButtons: {
            remindAll: 'Send reminder to all',
            revokeCart: 'Revoke cart',
            startDigitalSignature: 'Start Digital Signature',
            terminateCart: 'Terminate cart',
          },
          notReminded: ' - not reminded yet',
          notSigned: 'not signed yet',
          reminded: ' - last reminded at {{lastRemindedDate}}',
          signed: 'signed at {{signDate}}',
          signeeManagement: {
            copiedLink: 'Copied link to clipboard!',
            copiedPassword: 'Copied password to clipboard!',
            copyLink: 'Copy Link',
            copyPassword: 'Copy Password',
            expandLinkValidity: {
              button: 'Increase link validity time',
              dateNotValidError:
                'The expiration date couldn`t be changed, because it is before the current date. Please choose a new date, which follows after the current date.',
              notFound: 'The expiration date couldn`t be changed',
              success: 'The link validity time was successfully increased.',
            },
            generateNewLink: {
              button: 'Generate new link',
              notFound: 'No new link couldn`t be generated',
              success: 'New signing link was successfully generated.',
            },
            generateNewPassword: {
              button: 'Generate new password',
              notFound: 'No new password couldn`t be generated',
              success: 'New password was successfully generated.',
              usePasswordNotEnabledError:
                'No new password couldn`t be generated, because the digital signature process doesn`t use passwords.',
            },
            link: 'Link:',
            notFoundError: '{{specificMessage}}, because the signing process isn`t active anymore.',
            password: 'Password:',
            resendPassword: {
              button: 'Resend password',
              notFound: 'The password couldn`t be sent',
              success: 'The send of password was successfully requested.',
              usePasswordNotEnabledError:
                'The password couldn`t be sent, because the digital signature process doesn`t use passwords.',
            },
            sendRemainderMail: {
              button: 'Send remainder email',
              notFound: 'No remainder couldn`t be sent',
              success: 'The send of a remainder email was successfully requested.',
            },
            showLink: 'Show Link',
            showPassword: 'Show Password',
          },
          stateRowLabel: 'Digital Signature State',
          states: {
            DRAFT: 'draft',
            REVOKED: 'revoked',
            SIGNED: 'signed',
            STARTED: 'started',
            TERMINATED: 'terminated',
          },
          title: 'Digital Signature',
          userHasntSignedYetStateAdditional: ' (You haven`t signed yet)',
        },
        GeneralProjectData: {
          generalProjectData: 'Project',
          projectName: 'Project name',
        },
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use view shopping cart',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        HistoricRevisions: {
          title: 'Historic Revision of this Shopping Cart Entry',
        },
        Overview: {
          fields: {
            company: 'Company',
            companyIdentifier: 'Company identifier',
            contacts: 'Contacts',
            createdAt: 'Created at',
            createdBy: 'Created by',
            meetingAt: 'Meeting at',
            monthlyAdvance: 'Value: {{monthlyPaymentTitle}}',
            monthlyFeeMode: 'Monthly Fee Mode',
            monthlyFixedFee: 'Fixed Monthly Price',
            monthlyPaymentDecision: {
              decideLater: 'Not decided yet',
              no: 'Inactive',
              yes: 'Active',
            },
            monthlyPaymentStatus: 'Selection: {{monthlyPaymentTitle}}',
            number: 'Order Card Entry Number',
            startOfContract: 'Start Of Contract',
            status: 'Status',
            valueMonthly: 'Monthly Value',
            valueOneOff: 'One off Value',
            valueYearly: 'Yearly Value',
          },
          title: 'Shopping Cart overview',
        },
        RemindAllSigneesModal: {
          buttons: {
            cancel: 'Cancel',
            openEmailEditor: 'Open email editor',
            send: 'Send',
          },
          emailDrawer: {
            onCloseModal: {
              cancelButtonLabel: 'Cancel and not send reminder',
              message: 'CAUTION: When you close this window no reminder email will be sent.',
              okButtonLabel: 'Return',
            },
            title: 'Remind signees about signing process',
          },
          emailTemplateSelect: {
            emailTemplate: 'email template',
            error: 'Select an email template',
            label: 'Which email template do you like to use?',
            placeholder: 'No email template selected',
          },
          onlyUnsignedSigneesSwitch: {
            label: 'Would you like to remind only signees, which haven`t already signed?',
          },
          signeeTypeSelect: {
            all: 'All',
            company: 'Only clients',
            label: 'Which signees should get a reminder email?',
            tenant: 'Only team members',
          },
          title: 'Send reminder mail towards all signees',
        },
        RevokeDigitalSignatureModal: {
          buttons: {
            no: 'No',
            yes: 'Yes',
          },
          editModeAlert:
            'This cart has an open digital signature process. Before you can edit this cart, you have to revoke the digital signature process on this cart.',
          internalNoteLabel: 'Optional internal note for revoke',
          isForEditShoppingCart: {
            buttons: {
              yes: 'Revoke shopping cart and edit shopping cart',
            },
            title: 'Edit shopping cart - started digital signature process',
          },
          notificationSwitch: {
            label: 'Notify signees by email',
            off: 'Off',
            on: 'On',
          },
          title: 'Would you really like to cancel the Digital Signature Process?',
        },
        ShoppingCartHistoricItems: {
          DigitalSignature: {
            allSignedFailed: 'Digital signature process failed (Documents couldn`t be signed)',
            autoSigned: 'Shopping cart automatically signed by <gray>{{signeeFirstName}} {{signeeLastName}}</gray>',
            linkExpirationDateChanged:
              'Expiration date for link of <gray>{{signeeEmail}}</gray> extended from <gray>{{oldDate}}</gray> to <gray>{{newDate}}</gray>',
            linkRequested: 'Link for <gray>{{signeeEmail}}</gray> has been requested',
            monthlyPaymentDecisionTakenNo: '<gray>{{signeeEmail}}</gray> has decided against a monthly payment',
            monthlyPaymentDecisionTakenYes: '<gray>{{signeeEmail}}</gray> has decided for a monthly payment',
            newLink: 'New link for <gray>{{signeeEmail}}</gray> had been requested',
            newPassword: 'New password for <gray>{{signeeEmail}}</gray> has been created',
            passwordMailSend: 'Password email for digital signature process sent',
            passwordRequested: 'Password for <gray>{{signeeEmail}}</gray> has been requested',
            passwordResended: 'Password email for <gray>{{signeeEmail}}</gray> has been requested',
            remindAll: 'Reminder emails for <gray>{{signeeEmails}}</gray> has been requested',
            remindSignee: 'Reminder email for <gray>{{signeeEmail}}</gray> has been requested',
            reminderMailSend: 'Reminder email for digital signature sent',
            revokeNotificationMailSend: 'Email for revoked shopping cart sent',
            revoked: 'Shopping cart revoked',
            sepaMandateGranted: 'Client granted SEPA mandate',
            sepaMandateGrantedCard: {
              accountOwnerName: 'Account owner name',
              bankaccountInformation: 'Bank account informations',
            },
            sepaMandateNotGranted: 'Client didn’t grant SEPA mandate',
            signed: 'Signed by',
            signedDocsMailSend: 'Email for successful finished signature process sent',
            signeeOpenedNotificationMailSend: 'Email notification about the first opening by signee sent',
            signeeSignedNotificationMailSend: 'Email notification about, that signee signed, sent',
            startFailedPdf: 'Starting the signature process failed (At least one document could not be generated.)',
            startMailSendForCompanySignee: 'Client start email for digital signature process sent',
            startMailSendForTenantSignee: 'Tenant start email for digital signature process sent',
            started: 'Digital signature process started',
            successfulFinished: 'Digital signature process finished',
          },
          ShoppingCart: {
            changeStatus: 'Status changed from {{oldStatusName}} to {{newStatusName}}',
            created: 'Shopping cart{{revision}} created',
            deleted: 'Shopping cart{{revision}} deleted',
            sentByEmail: 'Shopping cart sent by email',
          },
          revision: ' revision',
          sendingError: 'Error appeared sending email',
          title: 'Shopping Cart history items',
        },
        columns: {
          actions: '$t(common.columns.actions)',
          category: '$t(common.columns.category)',
          createdAt: '$t(common.columns.createdAt)',
          createdBy: '$t(common.columns.createdBy)',
          data: '$t(common.columns.data)',
          document: '$t(common.columns.document)',
          inputFields: 'Input Values',
          inputParameters: 'Input-Parameters (use)',
          internalNote: '$t(common.columns.internalNote)',
          interval: '$t(common.columns.interval)',
          item: '$t(common.columns.item)',
          price: '$t(common.columns.price)',
          selectedCategories: 'Selected Categories',
          selectedItems: 'Selected Items',
          value: '$t(common.columns.value)',
          valueMonthly: '$t(common.columns.valueMonthly)',
          valueOneOff: '$t(common.columns.valueOneOff)',
          valueYearly: '$t(common.columns.valueYearly)',
        },
        customInternalValue: 'custom',
        editShoppingCart: 'Edit shopping cart',
        feeMode: {
          fixedMonthly: '$t(onboarding.pricingMode.fixedMonthlyModeTitle)',
          standard: '$t(onboarding.pricingMode.standardModeTitle)',
        },
        menu: {
          delete: {
            disabledOnDsTooltip:
              'This shopping cart has an open digital signature process. You have to revoke the digital signature process, before you can delete the cart.',
            label: 'Delete the shopping cart',
          },
          disableDuplicate: 'You can NOT duplicate this shopping-cart because its core data has changed.',
          duplicate: 'Duplicate shopping-cart',
          remindAll: 'Send reminder to all',
          revoke: 'Revoke Digital Signature Process',
        },
        migratedAlert:
          'This shopping cart was migrated to independent mode on {{migrationDate}}. This means that the independent configuration might contain other data as you have initially agreed on with the company. Please the PDFs attached to see original agreement and manually check.',
        noRecipientsWithEmail: 'There are no recipients',
        rows: {
          calculationMode: '$t(common.fields.calculationMode.label)',
          highlightBindingness: '$t(common.fields.highlightBindingness.label)',
          minPrice: '$t(common.fields.minPrice.label)',
          name: '$t(common.fields.name.label)',
          paymentInterval: '$t(common.fields.paymentInterval.label)',
          pricingFormula: '$t(common.fields.pricingFormula.label)',
          scales: 'Scales',
        },
        sendEmailButton: 'Open email editor',
        sendEmailConfirmationTitle: 'Are you sure you want to send the email?',
        sendReminderButton: 'Send reminder',
        signCartButton: 'Start signing',
        startDigitalSignature: 'Start Digital Signature Process',
        subtitle: 'created at {{createdAt}} by {{createdBy}}',
        title: 'View Shopping-Cart for {{companyName}}',
      },
    },
  },
};
