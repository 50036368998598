import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback } from 'react';
import ImportFromCommonLibrary from 'components/common/ImportFromCommonLibrary';
import { adminEmailTemplatesQuery } from 'graphql/queries';
import cn from 'classnames';
import { Collapse } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import '../../../components/common/ImportFromCommonLibrary.less';
import { useTranslation } from 'react-i18next';
import { importEmailTemplatesFromLibrary } from 'graphql/methods/admin/emailTemplates';
import { pick } from 'lodash';
import { RichEditorField } from 'components/common/RichEditor';
import { PLACEHOLDER_USE } from '@JavaScriptSuperstars/kanzleipilot-shared';

const { Panel } = Collapse;

const RenderItem = ({ data: emailTemplate, isSelected, isDuplicated, handleSelect }) => {
  const { t } = useTranslation();

  const { _id, name, subject, body } = emailTemplate;

  return (
    <div className={cn('import-items', isDuplicated ? 'import-duplicated' : null)}>
      <Collapse>
        <Panel forceRender header={name} key={_id}>
          <div>{t('admin.EmailTemplates.fields.subject.label')}:</div>
          <RichEditorField className="margin-bottom-16" readOnly initialValue={subject} />
          <div>{t('admin.EmailTemplates.fields.body.label')}:</div>
          <RichEditorField key={body} readOnly initialValue={body} allowedUses={[PLACEHOLDER_USE.MAIL_BODY]} />
        </Panel>
      </Collapse>
      <button className="import-add-btn" type="button" onClick={() => handleSelect(emailTemplate, !isSelected)}>
        {isSelected ? <CheckOutlined /> : null}
      </button>
    </div>
  );
};
const RenderItemMemo = memo(RenderItem, equal);

const ImportEmailTemplates = ({ visible, handleClose }) => {
  const { t } = useTranslation();

  const onOk = useCallback(async (values) => {
    await importEmailTemplatesFromLibrary({
      emailTemplates: values?.map((emailTemplate) => pick(emailTemplate, ['_id', 'name'])),
    });
  }, []);
  const errorMessage = useCallback(
    (duplicatedEmailTemplates) => {
      return t('admin.EmailTemplates.importErrorMessage', {
        duplicatedEmailTemplates,
      });
    },
    [t],
  );
  return (
    <ImportFromCommonLibrary
      visible={visible}
      emptyMessage={t('admin.EmailTemplates.importEmptyEmailTemplateListMessage')}
      modalProps={{ visible }}
      title={t('admin.EmailTemplates.importModalTitle')}
      errorMessage={errorMessage}
      query={adminEmailTemplatesQuery}
      handleClose={handleClose}
      onOk={onOk}
      renderItem={RenderItemMemo}
    />
  );
};

export default memo(ImportEmailTemplates, equal);
