import { gql } from '@apollo/client';
import documentTemplateBlockDescriptorFragment from './blockDescriptorFragment';

const adminDocumentTemplateByIdFragment = gql`
  fragment adminDocumentTemplateByIdFragment on DocumentTemplate {
    _id
    name
    order
    description
    lastUsedDocumentNr
    nrOfDocumentsCreated
    createdAt
    blocks {
      _id
      name
      props
      descriptor {
        ...documentTemplateBlockDescriptorFragment
      }
    }
  }
  ${documentTemplateBlockDescriptorFragment}
`;

export default adminDocumentTemplateByIdFragment;
