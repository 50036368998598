import { Button } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import inputConfirmModal from 'utils/inputConfirmModal';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import {
  createStaticItem,
  deleteStaticItemWithConfirmation,
  moveCategoryStaticItem,
  updateCategory,
  updateStaticItem,
} from 'graphql/methods';
import { TableMemo } from 'memo';
import { memo, useCallback, useMemo } from 'react';
import EmptyBox from 'components/common/EmptyBox';
import { useLibraryContext } from 'contexts/LibraryContext';
import { adminGetCategoryListQuery } from 'graphql/queries';
import { updateCategoryStaticItemCache } from 'graphql/cache';
import { RichEditorField } from 'components/common/RichEditor';
import { withSingleLine } from 'components/common/RichEditor/plugins/withSingleLine';
import MoreInfoWidget from 'components/common/MoreInfoWidget';
import equal from 'fast-deep-equal/es6/react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import SaveChangesButton from 'components/common/SaveChangesButton';
import I18nFormik from 'components/common/I18nFormik';
import Form from 'antd/lib/form/Form';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import { DragHandle, useDragTableComponents, useSortEnd } from './drag/DragTable';

const staticItemSchema = () => ({
  name: Yup.string()
    .required()
    .label(i18n.t('admin.staticItemsFieldModal.nameFieldDescription'))
    .test(
      'is-empty',
      i18n.t('admin.staticItemsFieldModal.errors.isEmpty', {
        label: i18n.t('admin.staticItemsFieldModal.nameFieldDescription'),
      }),
      function isEmpty(value) {
        return !RichText.isRichTextEmpty(value);
      },
    ),
  value: Yup.string()
    .required()
    .label(i18n.t('admin.staticItemsFieldModal.valueFieldLabel'))
    .test(
      'is-empty',
      i18n.t('admin.staticItemsFieldModal.errors.isEmpty', {
        label: i18n.t('admin.staticItemsFieldModal.valueFieldLabel'),
      }),
      function isEmpty(value) {
        return !RichText.isRichTextEmpty(value);
      },
    ),
});

const staticItemValidationSchema = () => Yup.object().shape(staticItemSchema({}));

const staticItemAllowedModifiers = [];
const modifyPlaceholderSchema = (e) => {
  const variablePlaceholderSchema = e.find((element) => element.name === 'variables');
  const filteredVariables = variablePlaceholderSchema.fields.filter((element) => element.type !== 'text');

  return [{ ...variablePlaceholderSchema, fields: filteredVariables }];
};

const getStaticItemFields = () => [
  {
    label: i18n.t('admin.staticItemsFieldModal.nameFieldLabel'),
    name: 'name',
    render: () => (
      <RichEditorField
        allowedModifiers={staticItemAllowedModifiers}
        modifyPlaceholderSchema={modifyPlaceholderSchema}
        name="name"
        label={i18n.t('admin.staticItemsFieldModal.nameFieldLabel')}
        withPlugins={withSingleLine}
        toolbarProps={{ isAlwaysShow: true }}
      />
    ),
  },
  {
    label: i18n.t('admin.staticItemsFieldModal.valueFieldLabel'),
    name: 'value',
    render: () => (
      <RichEditorField
        allowedModifiers={staticItemAllowedModifiers}
        modifyPlaceholderSchema={modifyPlaceholderSchema}
        name="value"
        label={i18n.t('admin.staticItemsFieldModal.valueFieldLabel')}
        withPlugins={withSingleLine}
        toolbarProps={{ isAlwaysShow: true }}
      />
    ),
  },
];
const addStaticItem = (categoryId, categoryListQuery) =>
  inputConfirmModal({
    validationSchema: staticItemValidationSchema,
    headerText: i18n.t('admin.staticItemsFieldModal.addModalTitle'),
    fields: getStaticItemFields(),
    value: { name: RichText.getDefaultRichEditorValue(), value: RichText.getDefaultRichEditorValue() },
    onSubmit: ({ name, value }) => createStaticItem({ categoryId, name, value, categoryListQuery }),
    errorResolver: { Duplicated: ['name', i18n.t('admin.staticItemsFieldModal.duplicatedErrorMessage')] },
    okText: i18n.t('admin.discountUsedInShoppingCartConfirmation.ok'),
    cancelText: i18n.t('admin.discountUsedInShoppingCartConfirmation.cancel'),
  });
const editStaticItem = ({ _id, currentName, currentValue }) =>
  inputConfirmModal({
    validationSchema: staticItemValidationSchema,
    headerText: i18n.t('admin.staticItemsFieldModal.editModalTitle'),
    onSubmit: async ({ name, value }) => updateStaticItem({ _id, name, value }),
    fields: getStaticItemFields(),
    cancelText: i18n.t('admin.discountUsedInShoppingCartConfirmation.cancel'),
    okText: i18n.t('admin.discountUsedInShoppingCartConfirmation.ok'),
    value: { name: currentName, value: currentValue },
    errorResolver: { Duplicated: ['name', i18n.t('admin.staticItemsFieldModal.duplicatedErrorMessage')] },
  });
const staticItemsTableColumns = ({ t, categoryListQuery }) => [
  {
    className: 'drag-visible',
    width: 10,
    render: () => <DragHandle />,
    key: 'sort',
  },
  {
    className: classNames('col-6', 'table-text'),
    title: t('admin.staticItemsTableColumns.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    className: classNames('col-6', 'table-text'),
    title: t('admin.staticItemsTableColumns.value'),
    dataIndex: 'value',
    key: 'value',
  },
  {
    title: t('admin.staticItemsTableColumns.actions'),
    dataIndex: '',
    key: 'actions',
    className: 'action-column-2',
    render: ({ _id, nameRaw, valueRaw }) => {
      return (
        <>
          <Button
            className="ant-btn-default"
            ghost
            type="primary"
            icon={<EditOutlined />}
            onClick={() => editStaticItem({ _id, currentName: nameRaw, currentValue: valueRaw })}
          />{' '}
          <Button
            className="ant-btn-default"
            type="danger"
            ghost
            icon={<DeleteOutlined />}
            onClick={() => deleteStaticItemWithConfirmation({ _id, categoryListQuery })}
          />
        </>
      );
    },
  },
];

const StaticItemsHelperWidget = ({ buttonClassName }) => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonClassName={buttonClassName}
      buttonText={t('admin.CatalogueConfiguration.StaticItemsHelperWidget.howUseButton')}
      title={t('admin.CatalogueConfiguration.StaticItemsHelperWidget.modalInfo.title')}
      helpText={t('admin.CatalogueConfiguration.StaticItemsHelperWidget.modalInfo.helpText')}
      videoCaption={t('admin.CatalogueConfiguration.StaticItemsHelperWidget.modalInfo.videoCaption')}
      videoUrl={t('admin.CatalogueConfiguration.StaticItemsHelperWidget.modalInfo.videoUrl')}
      imageUrl={t('admin.CatalogueConfiguration.StaticItemsHelperWidget.modalInfo.imageUrl')}
    />
  );
};

const StaticItemsHelperWidgetMemo = memo(StaticItemsHelperWidget, equal);

const StaticItemsInfoSchema = () => Yup.object().shape({});
export const IntroFormikInput = ({ props }) => {
  const { t } = useTranslation();
  return (
    <RichEditorField
      label={t('admin.CatalogueConfiguration.StaticItemsTab.inputs.intro.label')}
      tooltip={t('admin.CatalogueConfiguration.StaticItemsTab.inputs.intro.tooltip')}
      rootElement="paragraph"
      name="introForStaticItem"
      {...props}
    />
  );
};
export const OutroFormikInput = ({ props }) => {
  const { t } = useTranslation();
  return (
    <RichEditorField
      label={t('admin.CatalogueConfiguration.StaticItemsTab.inputs.outro.label')}
      tooltip={t('admin.CatalogueConfiguration.StaticItemsTab.inputs.outro.tooltip')}
      name="outroForStaticItem"
      rootElement="paragraph"
      {...props}
    />
  );
};

const StaticItemsInfo = ({ _id, introForStaticItem, outroForStaticItem }) => {
  const initialValues = useMemo(
    () => ({
      introForStaticItem: introForStaticItem ?? RichText.getDefaultRichEditorValue(),
      outroForStaticItem: outroForStaticItem ?? RichText.getDefaultRichEditorValue(),
    }),
    [introForStaticItem, outroForStaticItem],
  );
  return (
    <I18nFormik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(modifier) => {
        updateCategory({
          _id,
          modifier,
        });
      }}
      validationSchema={StaticItemsInfoSchema}
    >
      <Form layout="vertical">
        <AlertFromFormik />
        <IntroFormikInput />
        <OutroFormikInput />
        <SaveChangesButton initialValues={initialValues} />
      </Form>
    </I18nFormik>
  );
};
const StaticItemsInfoMemo = memo(StaticItemsInfo, equal);

const StaticItemsTab = ({ _id, staticItems, introForStaticItem, outroForStaticItem }) => {
  const { t } = useTranslation();

  const { isLibrary } = useLibraryContext();
  const categoryListQuery = adminGetCategoryListQuery(isLibrary);

  const moveInCache = useCallback((newData) => updateCategoryStaticItemCache({ _id, staticItems: newData }), [_id]);

  const { onSortEnd } = useSortEnd({
    dataSource: staticItems,
    moveInCache,
    moveMutation: moveCategoryStaticItem,
    refetchQuery: categoryListQuery,
  });
  const { DraggableContainer, DraggableBodyRow } = useDragTableComponents({ dataSource: staticItems, onSortEnd });

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        className="margin-right-16"
        onClick={() => addStaticItem(_id, categoryListQuery)}
        type="primary"
      >
        {t('admin.addStaticItemButton')}
      </Button>
      <StaticItemsHelperWidgetMemo />
      <br />
      <br />
      <div className="table-wrapper margin-bottom-16">
        <TableMemo
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
          columns={staticItemsTableColumns({ t, categoryListQuery })}
          dataSource={staticItems}
          locale={{ emptyText: <EmptyBox label={t('admin.CatalogueConfiguration.emptyStaticItemListMessage')} /> }}
          pagination={false}
          rowKey="_id"
        />
      </div>
      <StaticItemsInfoMemo _id={_id} introForStaticItem={introForStaticItem} outroForStaticItem={outroForStaticItem} />
    </>
  );
};
export default memo(StaticItemsTab, equal);
