import { useCallback, memo, useMemo, forwardRef } from 'react';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { pick } from 'lodash';
import cn from 'classnames';
import equal from 'fast-deep-equal/es6/react';
import { importDocumentTemplatesFromLibrary } from 'graphql/methods';
import ImportFromCommonLibrary, { ImportFromLibrary } from 'components/common/ImportFromCommonLibrary';
import { adminDocumentTemplateListForImportModalQuery, adminDocumentTemplateListQuery } from 'graphql/queries';
import ImportDocumentFromLibraryHelperWidget from './ImportDocumentFromLibraryHelperWidget';
import '../../../common/ImportFromCommonLibrary.less';

const { Panel } = Collapse;

const RenderItem = ({ data: document, isSelected, isDuplicated, handleSelect }) => {
  const { _id, name, description } = document;
  return (
    <div className={cn('import-items', isDuplicated ? 'import-duplicated' : null)}>
      <Collapse>
        <Panel forceRender header={name} key={_id}>
          {description}
        </Panel>
      </Collapse>
      <button className="import-add-btn" type="button" onClick={() => handleSelect(document, !isSelected)}>
        {isSelected ? <CheckOutlined /> : null}
      </button>
    </div>
  );
};
const RenderItemMemo = memo(RenderItem, equal);

const ImportDocumentFromLibraryModal = ({ visible, handleClose }) => {
  const { t } = useTranslation();

  const errorMessage = useCallback(
    (duplicatedDocuments) => {
      return t('admin.DocumentTemplateListPage.errorMessageInModal', {
        duplicatedDocuments,
      });
    },
    [t],
  );

  const onOk = useCallback(async (values) => {
    await importDocumentTemplatesFromLibrary(values.map((category) => pick(category, ['_id', 'name'])));
  }, []);

  const title = useMemo(
    () => (
      <>
        <span>{t('admin.DocumentTemplateListPage.importFromLibraryModalTitle')}</span>
        <div>
          <ImportDocumentFromLibraryHelperWidget />
        </div>
      </>
    ),
    [t],
  );
  return (
    <ImportFromCommonLibrary
      alert={t('onboarding.pickDocuments.infoMessage')}
      emptyMessage={t('admin.DocumentTemplateListPage.importEmptyListMessage')}
      errorMessage={errorMessage}
      handleClose={handleClose}
      modalProps={{ visible }}
      onOk={onOk}
      query={adminDocumentTemplateListForImportModalQuery}
      renderItem={RenderItemMemo}
      title={title}
    />
  );
};

export const ImportDocumentFromLibrary = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const errorMessage = useCallback(
    (duplicatedCategories) => {
      return t('admin.importCategoryPresetsModal.errorMessage', {
        duplicatedCategories,
      });
    },
    [t],
  );
  return (
    <ImportFromLibrary
      ref={ref}
      {...props}
      emptyMessage={t('admin.DocumentTemplateListPage.importEmptyListMessage')}
      errorMessage={errorMessage}
      query={adminDocumentTemplateListQuery}
      renderItem={RenderItemMemo}
    />
  );
});

export default memo(ImportDocumentFromLibraryModal, equal);
