import { useTranslation, Trans } from 'react-i18next';
import { useSigningProcessShoppingCartContext } from 'contexts/SigningProcessShoppingCartContext';
import { getPriceInformationOfService } from './ServicePrice.utils';
import OnActualCostsInfo from './OnActualCostsInfo';
import AdditionalPriceInformation from './AdditionalPriceInformation';

import classes from './ServiceEntry.module.less';

/**
 * Get text for provided payment interval
 * @param {String} paymentInterval - Payment interval
 * @param {Function} t - Translation function
 * @returns {String} translated payment interval text
 */
const getPaymentIntervalText = (paymentInterval, t) => t(`common.Item.paymentIntervalValue.${paymentInterval}`);

/**
 * ActualCostComponent component to show price of service paid by actual costs for service overview step for signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {ServicePriceInformation} - Price information of service
 * @returns {JSX.Element} shows actual cost tag for total price of a service
 * @component
 */
const ActualCostComponent = ({ priceInformations }) => {
  const { t } = useTranslation();
  return (
    <span>
      <OnActualCostsInfo /> {getPaymentIntervalText(priceInformations.paymentInterval, t)}
    </span>
  );
};

/**
 * FixedFeeComponent component to show price included in fixed fee for service overview step for signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {ServicePriceInformation} - Price information of service
 * @returns {JSX.Element} shows text that price is included in fixed fee
 * @component
 */
const FixedFeeComponent = ({ priceInformations }) => {
  const { t } = useTranslation();
  return (
    <span>
      <Trans
        i18nKey="signing.serviceOverviewStep.fixedFeeInfo"
        values={{
          paymentIntervalText: getPaymentIntervalText(priceInformations.paymentInterval, t),
        }}
      />
    </span>
  );
};

/**
 * PriceComponent component to show normal price for service overview step for signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {ServicePriceInformation} - Price information of service
 * @returns {JSX.Element} shows price with payment interval
 * @component
 */
const PriceComponent = ({ priceInformations }) => {
  const { t } = useTranslation();
  return (
    <span>
      {`${priceInformations.formattedTotalPriceOfService} ${getPaymentIntervalText(
        priceInformations.paymentInterval,
        t,
      )}`}
    </span>
  );
};

/**
 * PriceInformationForService component which shows the price and the related informations about the price for a service in the service overview step for the signing process (Requires signing process shopping cart context)
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.item - Service object
 * @param {ActiveDiscount} inputParameters.activeDiscount - Active discount on category level
 * @returns {JSX.Element} price and price informations for a service
 * @component
 */
const PriceInformationForService = ({ item, activeDiscount }) => {
  const { shoppingCart } = useSigningProcessShoppingCartContext();

  if (!item || !shoppingCart) return null;

  const priceInformations = getPriceInformationOfService(item, shoppingCart, activeDiscount, {
    actualCostComponent: ActualCostComponent,
    fixedFeeComponent: FixedFeeComponent,
    priceComponent: PriceComponent,
  });

  return (
    <div className={classes.priceInfos}>
      <span className={classes.price}>{priceInformations.formattedPriceComponent}</span>
      <AdditionalPriceInformation priceInformations={priceInformations} activeDiscount={activeDiscount} />
    </div>
  );
};

export default PriceInformationForService;
