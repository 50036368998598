import { useTranslation } from 'react-i18next';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';

/**
 * NoteToClient component, which shows the information for the client
 * @param {Object} inputParameters - Input parameters of component
 * @param {String} inputParameters.noteToClient - Json string with note to client
 * @returns {JSX.Element} paragraph with note to client of a service
 * @component
 */
const NoteToClient = ({ noteToClient }) => {
  const { t } = useTranslation();
  if (!noteToClient) return null;
  if (noteToClient === '[{"type":"div","children":[{"text":""}]}]') return null;
  return (
    <p>
      <b>{t('signing.serviceOverviewStep.service.noteForClient')}</b>
      {RichText.renderRichText(noteToClient)}
    </p>
  );
};

export default NoteToClient;
