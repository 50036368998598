import { InputNumber } from 'components/common/InputNumber';
import FormItem from 'components/common/FormItem';
import { Input } from 'formik-antd';

export const PricingTable = {
  rows: [
    'yearlyBusinessAccountsSingle',
    'yearlyBusinessAccountsCompanies',
    'yearlyBusinessAccountsBalanceSheetBEU',
    'yearlyBusinessAccountsBalanceSheetRest',
    'monthlyBookkeeping',
    'yearlyPersonalAccounts',
  ],
  getColumns: ({ t, unitName }) => [
    {
      title: t('onboarding.pricingLevel.pricingTable.category'),
      key: 'name',
      dataIndex: 'name',
      render: (name) => t(`onboarding.pricingLevel.pricingTable.${name}`),
    },
    {
      title: t('onboarding.pricingLevel.pricingTable.pricingFactor'),
      key: 'name',
      render: ({ name }) => (
        <FormItem name={name}>
          <InputNumber name={name} addonAfter={unitName} />
        </FormItem>
      ),
      width: 150,
    },
  ],
};

export const HourlyRatesTable = {
  rows: ['accountant', 'employee'],
  getColumns: ({ t, unitName }) => [
    {
      title: t('onboarding.pricingLevel.hourlyRatesTable.jobType'),
      key: 'name',
      dataIndex: 'name',
      render: (name) => t(`onboarding.pricingLevel.hourlyRatesTable.${name}`),
    },
    {
      title: t('onboarding.pricingLevel.hourlyRatesTable.pricingFactor'),
      key: 'name',
      render: ({ name }) => (
        <FormItem name={name}>
          <InputNumber name={name} addonAfter={unitName} />
        </FormItem>
      ),
      width: 150,
    },
  ],
};

export const OtherSettingsTable = {
  rows: ['tenantSoftware'],
  getColumns: ({ t }) => [
    {
      title: t('onboarding.pricingLevel.otherSettingsTable.variable'),
      key: 'name',
      dataIndex: 'name',
      render: (name) => t(`onboarding.pricingLevel.otherSettingsTable.${name}`),
    },
    {
      title: t('onboarding.pricingLevel.otherSettingsTable.value'),
      key: 'name',
      render: ({ name }) => (
        <FormItem name={name}>
          <Input name={name} />
        </FormItem>
      ),
      width: 400,
    },
  ],
};
