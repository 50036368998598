import { useTranslation } from 'react-i18next';
import PageContainer from 'components/layout/PageContainer';
import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { isAdmin as isAdminRole } from 'utils/role';
import roles from 'constants/roles';
import { useMemo } from 'react';
import { CalendarOutlined, MailOutlined } from '@ant-design/icons';
import OnboardingChecklist from 'pages/Dashboard/ChecklistCard';
import { FaEuroSign, FaPlus, FaShoppingCart, FaUsers, FaCompass, FaLifeRing } from 'react-icons/fa';
import cn from 'classnames';
import Card from 'components/common/Card';
import DashboardHelperWidget from './DashboardHelperWidget';
import DashboardIntroductionHelperWidget from './DashboardIntroductionHelperWidget';
import OpenSigningProcessesCard from './OpenSigningProcessesCard';
import classes from './Dashboard.module.less';

export default function DashboardPage() {
  const { t } = useTranslation();
  const [me] = useCurrentUser();
  const role = me?.role;
  const isAdmin = isAdminRole(role);
  const isViewer = useMemo(() => me?.role === roles.VIEWER, [me]);
  return (
    <PageContainer title={<b>{t('DashboardPage.title')}</b>} left={<DashboardHelperWidget />}>
      <Row gutter={10}>
        <Col sm={24} md={17}>
          <Card
            icon={<FaCompass />}
            title={t('DashboardPage.actionsBlock.title')}
            className={cn('ant-card-head-borderless', classes.actionCard)}
          >
            {!isViewer ? (
              <Link to={routePaths.shoppingCart}>
                <Button type="primary" icon={<FaPlus size={40} />} className={classes.primaryButtonAction}>
                  {t('DashboardPage.actionsBlock.createNewShoppingCart')}
                </Button>
              </Link>
            ) : null}
            <Link to={routePaths.shoppingCartEntries}>
              <Button icon={<FaShoppingCart size={40} />} className={classes.buttonAction}>
                {t('DashboardPage.actionsBlock.manageShoppingCarts')}
              </Button>
            </Link>
            {isAdmin ? (
              <>
                <Link to={routePaths.catalogueConfiguration}>
                  <Button icon={<FaEuroSign size={40} />} className={classes.buttonAction}>
                    {t('DashboardPage.actionsBlock.configureOurCatalogue')}
                  </Button>
                </Link>
                <Link to={routePaths.manageUsers}>
                  <Button icon={<FaUsers size={40} />} className={classes.buttonAction}>
                    {t('onboarding.finish.manageUsersButton')}
                  </Button>
                </Link>
              </>
            ) : null}
          </Card>
          <OpenSigningProcessesCard />
          <OnboardingChecklist />
        </Col>
        <Col sm={24} md={7}>
          <Card
            icon={<FaLifeRing />}
            title={t('DashboardPage.helpCard.title')}
            subtitle={<DashboardIntroductionHelperWidget buttonClassName="text-align-left margin-top-10" />}
            className="ant-card-head-borderless"
          >
            <h4>
              <b>{t('DashboardPage.helpCard.book.title')}</b>
            </h4>
            <div className={classes.helpCardBook}>
              <a href={t('DashboardPage.helpCard.book.calendlyUrl')} target="_blank" rel="noreferrer">
                <Button type="primary" icon={<CalendarOutlined />}>
                  {t('DashboardPage.helpCard.book.calendly')}
                </Button>
              </a>
              <a href={t('DashboardPage.helpCard.book.sendEmailUrl')} target="_blank" rel="noreferrer">
                <Button type="primary" icon={<MailOutlined />}>
                  {t('DashboardPage.helpCard.book.sendEmail')}
                </Button>
              </a>
            </div>
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
}
