import { useTranslation } from 'react-i18next';
import DiscountedInformation from './DiscountedInformation';
import CustomPriceInformation from './CustomPriceInformation';
import DiscountedCustomPriceInformation from './DiscountedCustomPriceInformation';

import classes from './ServiceEntry.module.less';

/**
 * AdditionalPriceInformation component, which shows details on the price and the bindingness
 * @param {Object} inputParameters - Input parameters of the component
 * @param {ServicePriceInformation} inputParameters.priceInformations - Informations about the price of the service
 * @param {ActiveDiscount} inputParameters.activeDiscount - Active discount of the service, if a discount is active on category level
 * @returns {JSX.Element} details component for the service entry in the service overview step in the signing process
 * @component
 */
const AdditionalPriceInformation = ({ priceInformations, activeDiscount }) => {
  const { t } = useTranslation();
  let priceInformationText = null;

  if (priceInformations.isCustomPrice && priceInformations.isDiscounted)
    priceInformationText = (
      <DiscountedCustomPriceInformation priceInformations={priceInformations} activeDiscount={activeDiscount} />
    );
  if (!priceInformations.isCustomPrice && priceInformations.isDiscounted)
    priceInformationText = (
      <DiscountedInformation priceInformations={priceInformations} activeDiscount={activeDiscount} />
    );
  if (priceInformations.isCustomPrice && !priceInformations.isDiscounted)
    priceInformationText = <CustomPriceInformation priceInformations={priceInformations} />;

  const highlightText = priceInformations.shouldHighlightBindingness
    ? t(`common.Item.highlightBindingness.${priceInformations.highlightBindingness}`)
    : '';
  return (
    <>
      <span className={classes.bindingness}>{priceInformationText}</span>
      <span className={classes.bindingness}>{highlightText}</span>
    </>
  );
};

export default AdditionalPriceInformation;
