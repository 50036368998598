import { useEffect } from 'react';
import moment from 'moment';
import { ApolloProvider } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import de from 'antd/lib/locale/de_DE';
import en from 'antd/lib/locale/en_GB';
import { setLocale } from 'yup';
import { throttle } from 'lodash';
import { de as deLocale, en as enLocale } from 'utils/yupLocale';
import vhCheck from 'vh-check';
import NotificationHandler from 'components/NotificationHandler';
import { SidebarContextProvider } from './contexts/SidebarContext';
import startup from './startup';
import RootRouter from './router/RootRouter';
import apolloClient from './graphql/apollo';
import 'normalize.css';
import './styles/index.less';
import './i18n';
import './testing';
import './componentPatch';

import('./i18n/usedKeysReporter'); // async import

startup();

const antdTranslations = {
  de,
  en,
};

const App = () => {
  vhCheck({ redefineVh: true }); // set var(--vh, 1vh) for real 1vh

  const { i18n } = useTranslation();
  useEffect(() => {
    moment.locale(i18n.language);
    switch (i18n.language) {
      case 'en':
        setLocale(enLocale);
        break;
      case 'de':
        setLocale(deLocale);
        break;
      default:
    }
  }, [i18n.language]);
  return (
    <ApolloProvider client={apolloClient}>
      <ConfigProvider locale={antdTranslations[i18n.language]}>
        <SidebarContextProvider>
          <DndProvider
            backend={isMobile ? TouchBackend : HTML5Backend}
            options={isMobile ? { enableMouseEvents: true } : {}}
          >
            <RootRouter />
            <NotificationHandler />
          </DndProvider>
        </SidebarContextProvider>
      </ConfigProvider>
    </ApolloProvider>
  );
};

// KJ-998
const updateViewportOffsetOnKeyboardOpen = throttle(() => {
  const newOffset = `${window.innerHeight - visualViewport.height}px`;
  const oldOffset = document.body.style.getPropertyValue('--viewport-offset');
  if (oldOffset !== newOffset) document.body.style.setProperty('--viewport-offset', newOffset);
}, 100);
visualViewport.addEventListener('resize', updateViewportOffsetOnKeyboardOpen);
updateViewportOffsetOnKeyboardOpen();

export default App;
